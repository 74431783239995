import React from 'react';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import style from './style.module.scss';
import { useHistory } from 'react-router-dom';

const GardenInfoItem = ({ item }) => {
  const history = useHistory();
  return (
    <div className={style.card} onClick={() => history.push('/garden/' + item.id)}>
      <div className={style.borderBottom}>
        <Col className={style.gardenTitle}>{item.name}</Col>
        <Col className={style.updateDate}>
          {item.updateDate && (
            <div>
              Última actualización:{' '}
              {item.updateDate.toLocaleString(undefined, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
              hs
            </div>
          )}
        </Col>
      </div>
      <div className={style.infoGarden}>
        <Col md="6">
          {item.nets?.length > 0 && (
            <Row className={style.itemInfo}>
              <div className={style.infoContainer}>
                <div className={style.iconInfo}>Red:</div>
                <span className={style.info}>{item.nets.toString().replaceAll(',', ', ')}</span>
              </div>
            </Row>
          )}
          {item.tel && (
            <Row className={style.itemInfo}>
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-phone" />
                </div>
                &nbsp;
                {item.tel}
              </div>
            </Row>
          )}
        </Col>
        <Col md="6">
          {item.location && (
            <Row className={style.itemInfo}>
              {' '}
              <div className={style.iconInfo}>
                <i className="fas fa-map-marker-alt" />
              </div>
              <span className={style.info}>{item.location}</span>
            </Row>
          )}
          {item.email && (
            <Row className={style.itemInfo}>
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-envelope"></i>
                </div>
                &nbsp;
                {item.email}
              </div>
            </Row>
          )}
        </Col>
      </div>
    </div>
  );
};

export default GardenInfoItem;

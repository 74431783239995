import React, { useEffect, useState } from 'react';
import { CardBody, Card, Container, Row, Col } from 'reactstrap';
import style from './style.module.scss';
import { apiGet, apiPost } from '../../services/services';
import Success from '../Utils/Success';
import Error from '../Utils/Error';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import AdminRequestArticleListItem from './AdminRequestArticleListItem';

const AdminRequestArticleList = () => {
  const [articleRequestAdd, setArticleRequestAdd] = useState([]);
  const [confirmationModalRequestAdd, setConfirmationModalRequestAdd] = useState({ show: false, reference: '' });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [articleRequested, setArticleRequested] = useState({ id: 0, accept: false });

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    apiGet('administrador/articulos/aceptar', {}).then(r => {
      if (r.error == false) {
        var articleAddArray = [];
        r.res.map(item => {
          return articleAddArray.push({
            id: item.id,
            name: item.nombre,
            type: item.tipo,
          });
        });
        setArticleRequestAdd(articleAddArray);
      }
    });
  };

  const ConfirmRequestAdd = (id, name, isAccept) => {
    setArticleRequested({ id: id, accept: isAccept });
    setConfirmationModalRequestAdd({ show: true, reference: name });
  };

  const ArticleSendRequestAdd = () => {
    var body = {
      articulo_id: articleRequested.id,
      aceptar: articleRequested.accept,
    };
    var message = articleRequested.accept ? 'Artículo aceptado correctamente' : 'Artículo rechazado correctamente';
    apiPost('administrador/articulos/aceptar/', body).then(r => {
      if (r.error == false) {
        setRegisterResponse({ show: true, error: r.error, message: message });
        update();
        setTimeout(() => {
          setRegisterResponse({ show: false, error: false, message: '' });
        }, 2000);
      } else {
        let message = 'Error en:';
        Object.entries(r.res).forEach(([key, errors], index) => {
          message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
            .toString()
            .toLowerCase()})`;
        });
        setRegisterResponse({ error: r.error, message });
      }
    });
  };

  return (
    <div className={style.body}>
      <div className={style.background}>
        <ConfirmationModal
          isOpen={confirmationModalRequestAdd.show}
          title={articleRequested.accept ? 'Confirmar Artículo' : 'Rechazar Artículo'}
          message={`${articleRequested.accept ? 'confirmar' : 'rechazar'} la solicitud del artículo ${
            confirmationModalRequestAdd.reference
          }`}
          onConfirm={() => ArticleSendRequestAdd()}
          onClose={() => setConfirmationModalRequestAdd({ show: false, reference: '' })}
        />
        {registerResponse.show && (
          <Container>
            <Row>
              <Col>
                {registerResponse.error ? (
                  <Error message={registerResponse.message} />
                ) : (
                  <Success message={registerResponse.message} />
                )}
              </Col>
            </Row>
          </Container>
        )}
        {articleRequestAdd.length > 0 ? (
          <Card>
            <CardBody>
              {articleRequestAdd.map((item, i) => {
                return (
                  <div key={i}>
                    <AdminRequestArticleListItem id={i} item={item} confirmRequest={ConfirmRequestAdd} />
                  </div>
                );
              })}
            </CardBody>
          </Card>
        ) : (
          <div>No hay solicitudes de artículos</div>
        )}
      </div>
    </div>
  );
};

export default AdminRequestArticleList;

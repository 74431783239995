import React from 'react';
import { Row, Col } from 'reactstrap';
import style from './style.module.scss';

const AboutUs = () => {
  return (
    <div className={style.body}>
      <div className={style.background}>
        <Row className={style.title}>
          <Col className={style.left}>
            Sistema de Información de Huertas del Oeste
          </Col>
        </Row>
        <Row className={style.bodyInfo}>
          <span className={style.info}>
            El Sistema de Información de Huertas del Oeste (SIHO) es una
            plataforma para que las huertas del municipio A puedan compartir
            información con el fin de mejorar la comunicación y colaboración
            entre diferentes huertas.
          </span>

          <span className={style.info}>
            El sistema fue desarrollado en el marco del programa APEX-Cerro de
            la Universidad de la República por estudiantes de Facultad de
            Ingeniería.
          </span>
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;

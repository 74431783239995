import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import {
  CardBody,
  Card,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
import { apiPost, apiGet } from '../../services/services';
import Error from '../Utils/Error';
import Success from '../Utils/Success';

const UpdateGardenReferent = ({ gardenId, toggle }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(localStorage.getItem('currentUser').replaceAll('"', ''));
  const [registerResponse, setRegisterResponse] = useState({
    error: false,
    message: '',
  });

  useEffect(() => {
    if (localStorage.getItem('is_superuser') === 'true') {
      apiGet('administrador/usuarios/', {}).then((r) => {
        if (r.error == false) {
          var usersArray = [];
          r.res.map((item) => {
            return usersArray.push({
              id: item.id,
              name: item.first_name,
              email: item.email,
              tel: item.telefono,
              isModerator: item.is_staff,
              isAdmin: item.is_superuser,
            });
          });
          setUsers(usersArray);
        }
      });
    }
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    var user = users.filter((item) => item.email === selectedUser);
    if (selectedUser != null){
      apiPost('administrador/referente/', {
        huerta_id: gardenId,
        user_id: user[0]?.id,
      }).then((r) => {
        if (r.error == false) {
          setRegisterResponse({
            show: true,
            error: r.error,
            message: 'Referente cambiado correctamente',
          });
          setTimeout(() => {
            toggle(false);
            window.location.reload();
            window.scrollTo(0,0);
          }, 2000);
        } else {
          setRegisterResponse({
            error: r.error,
            message: 'Error al cambiar el referente',
          });
        }
      });
    }
  };

  return (
    <div className={style.overlay}>
      <div className={style.body}>
        <div className={style.center}>
          <Card className={style.cardModal}>
            <CardBody>
              {registerResponse.show && (
                <Row>
                  <Col>
                    {registerResponse.error ? (
                      <Error message={registerResponse.message} />
                    ) : (
                      <Success message={registerResponse.message} />
                    )}
                  </Col>
                </Row>
              )}
              <Col className={style.titleModal} md="10" sm="10" xs="10">
                Cambiar el referente de la huerta
              </Col>
              <Form className={style.Form} onSubmit={(e) => submitForm(e)}>
                <FormGroup className={style.FormGroup}>
                  <Label for='title'>Seleccione un usuario</Label>
                  <Col>
                    <Input
                      type='select'
                      name='title'
                      id='title'
                      placeholder='Seleccione un usuario'
                      onChange={(e) => setSelectedUser(e.target.value)}
                    >
                      {users.map((user, i) => {
                        return <option key={i}>{user.email}</option>;
                      })}
                    </Input>
                  </Col>
                </FormGroup>
                <Row className={style.Button}>
                  <Col md='6' xs='6'>
                    <Button
                      className={style.ButtonSecondary}
                      size='lg'
                      onClick={() => toggle(false)}
                    >
                      Cerrar
                    </Button>
                  </Col>
                  <Col md='6' xs='6'>
                    <Button
                      type='submit'
                      className={style.ButtonPrimary}
                      size='lg'
                    >
                      Confirmar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UpdateGardenReferent;

export const customSelectStyle = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    if (data.value == 'agregarArticulo') {
      return {
        ...styles,
        backgroundColor: '#979797',
        cursor: 'pointer',
      };
    }
    return {
      ...styles,
      cursor: 'pointer',
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#4ca771',
      cursor: 'pointer',
    };
  },
};

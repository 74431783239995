import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Container,
  Card,
  CardBody,
} from 'reactstrap';
import style from './style.module.scss';
import { apiDelete, apiGet, apiPost } from '../../services/services';
import AdminUserListItem from './AdminUserListItem';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Error from '../Utils/Error';
import Success from '../Utils/Success';

const AdminUserList = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [confirmationModalDelete, setConfirmationModalDelete] = useState({ show: false, reference: '' });
  const [confirmationModalModerator, setConfirmationModalModerator] = useState({
    show: false,
    title: '',
    reference: '',
    action: '',
  });
  const [userUpdate, setUserUpdate] = useState({ id: 0, type: '' });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    if (localStorage.getItem('is_superuser') === 'true') {
      apiGet('administrador/usuarios/', {}).then(r => {
        if (r.error == false) {
          var usersArray = [];
          r.res.map(item => {
            return usersArray.push({
              id: item.id,
              name: item.first_name,
              email: item.email,
              tel: item.telefono,
              isModerator: item.is_staff,
              isAdmin: item.is_superuser,
            });
          });
          setUsers(usersArray);
          setAllUsers(usersArray);
        }
      });
    }
  };

  useEffect(() => {
    if (search !== '') {
      var filterUsers = allUsers.filter(
        user =>
          user.email.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          user.name.toLowerCase().indexOf(search.toLowerCase()) > -1,
      );
      setUsers(filterUsers);
    } else {
      setUsers(allUsers);
    }
  }, [search]);

  const DeleteUser = id => {
    users.forEach(user => {
      if (user.id == id) {
        setConfirmationModalDelete({ show: true, reference: user.email });
      }
    });
    setUserUpdate({ ...userUpdate, id: id, type: 'DELETE' });
  };

  const UpdateModerator = id => {
    users.forEach(user => {
      if (user.id == id) {
        var titleMessage = '';
        var actionMessage = '';
        if (user.isModerator) {
          titleMessage = 'Quitar rol moderador';
          actionMessage = 'quitar como';
          setUserUpdate({ ...userUpdate, id: id, type: 'UPDATE_DEL' });
        } else {
          titleMessage = 'Aplicar rol moderador';
          actionMessage = 'aplicar el rol de';
          setUserUpdate({ ...userUpdate, id: id, type: 'UPDATE_ADD' });
        }
        setConfirmationModalModerator({
          show: true,
          title: titleMessage,
          reference: user.email,
          action: actionMessage,
        });
      }
    });
  };

  const ConfirmDeleteUser = () => {
    setConfirmationModalDelete({ show: false, reference: '' });
    apiDelete('usuario/' + userUpdate.id + '/').then(r => {
      if (r.error) {
        setRegisterResponse({
          show: true,
          error: true,
          message: 'No se pudo eliminar el usuario',
        });
      } else {
        setRegisterResponse({
          show: true,
          error: false,
          message: 'Usuario eliminado correctamente',
        });
        update();
      }
    });
  };

  const ConfirmUpdateModerator = () => {
    setConfirmationModalModerator({ show: false, title: '', reference: '' });
    var body = {
      user_id: userUpdate.id,
    };
    if (userUpdate.type == 'UPDATE_ADD') {
      apiPost('administrador/moderador/', body).then(r => {
        if (r.error == false) {
          var usersUpdate = users;
          usersUpdate.forEach(user => {
            if (user.id == userUpdate.id) {
              user.isModerator = true;
            }
          });
          setUsers(usersUpdate);
          setRegisterResponse({ show: true, error: false, message: 'Usuario actualizado correctamente' });
        } else {
          setRegisterResponse({
            show: true,
            error: false,
            message: 'No se pudo actualizar el rol del usuario',
          });
        }
      });
    }
    if (userUpdate.type == 'UPDATE_DEL') {
      apiDelete('administrador/moderador/', body).then(r => {
        if (r.error == false) {
          var usersUpdate = users;
          usersUpdate.forEach(user => {
            if (user.id == userUpdate.id) {
              user.isModerator = false;
            }
          });
          setUsers(usersUpdate);
          setRegisterResponse({ show: true, error: false, message: 'Usuario actualizado correctamente' });
        } else {
          setRegisterResponse({
            show: true,
            error: true,
            message: 'No se pudo actualizar el rol del usuario',
          });
        }
      });
    }
  };

  return (
    <div className={style.body}>
      <div className={style.background}>
        <ConfirmationModal
          isOpen={confirmationModalDelete.show}
          title="Eliminar usuario"
          message={`eliminar la cuenta del usuario ${confirmationModalDelete.reference}`}
          onConfirm={() => ConfirmDeleteUser()}
          onClose={() => setConfirmationModalDelete({ show: false, reference: '' })}
        />
        <ConfirmationModal
          isOpen={confirmationModalModerator.show}
          title={confirmationModalModerator.title}
          message={`${confirmationModalModerator.action} moderador al usuario ${confirmationModalModerator.reference}`}
          onConfirm={() => ConfirmUpdateModerator()}
          onClose={() => setConfirmationModalModerator({ show: false, title: '', reference: '' })}
        />
        {registerResponse.show && (
          <Row>
            <Col>
              {registerResponse.error ? (
                <Error message={registerResponse.message} />
              ) : (
                <Success message={registerResponse.message} />
              )}
            </Col>
          </Row>
        )}
        <Container className={style.containerAdmin} fluid>
          <Row className={style.right}>
            <Col className={style.titleAdmin}>Usuarios</Col>
          </Row>
        </Container>
        <div className={style.paddingSearch}>
          <div className={style.subtitle}>Buscar un usuario</div>
          <InputGroup className={style.inputGroup}>
            <Input
              className={style.inputSearch}
              type="text"
              name="search"
              id="search"
              placeholder="Nombre de usuario"
              onChange={e => setSearch(e.target.value)}
            />
            <InputGroupAddon className={style.searchIcon}>
              <InputGroupText className={style.iconGroupText}>
                {' '}
                <i className="fas fa-search"></i>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
        {users.length > 0 ? (
          <Card>
            <CardBody>
              {users.map((item, i) => {
                return (
                  <div key={i}>
                    {' '}
                    <AdminUserListItem item={item} id={i} moderatorUpdate={UpdateModerator} deleteUser={DeleteUser} />
                  </div>
                );
              })}
            </CardBody>
          </Card>
        ) : (
          <div>No hay usuarios registrados</div>
        )}
      </div>
    </div>
  );
};

export default AdminUserList;

import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Container, Collapse } from 'reactstrap';
import style from './style.module.scss';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import AdminArticleItem from './AdminArticleItem';
import { GetLabelArticleType } from '../Utils/UtilFunctions';
import RegisterArticle from './RegisterArticle';
import { apiDelete, apiGet } from '../../services/services';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';

const AdminArticles = () => {
  const [articles, setArticles] = useState([]);
  const [confirmationModalDelete, setConfirmationModalDelete] = useState({ show: false, reference: '' });
  const [articleUpdate, setArticleUpdate] = useState({ id: 0, name: '', type: '' });
  const [toggleRegisterArticle, setToggleRegisterArticle] = useState(false);
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [linesCount, setLinesCount] = useState(3);
  const [toggleCollapse, setToggleCollapse] = useState([]);

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    var articlesArray = [];
    var articleCollapse = [];
    var articleExists = false;
    apiGet('administrador/articulos/', {}).then(r => {
      if (r.res && r.res.length > 0) {
        r.res.forEach(garArticle => {
          articleExists = false;
          articlesArray.forEach(article => {
            if (article.type == garArticle.tipo_articulo) {
              articleExists = true;
              article.array.push({ id: garArticle.id, name: garArticle.nombre });
            }
          });
          if (!articleExists) {
            articlesArray.push({
              type: garArticle.tipo_articulo,
              name: GetLabelArticleType(garArticle.tipo_articulo),
              array: [{ id: garArticle.id, name: garArticle.nombre }],
            });
            articleCollapse.push({ [garArticle.tipo_articulo]: false });
          }
        });
        setArticles(articlesArray);
        setToggleCollapse(articleCollapse);
      }
    });
  };

  const DeleteArticle = id => {
    articles.forEach(articleItem => {
      articleItem.array.forEach(item => {
        if (item.id == id) {
          setArticleUpdate({ id: id, name: item.name, type: '' });
          setConfirmationModalDelete({ show: true, reference: item.name });
        }
      });
    });
  };

  const ConfirmDeleteArticle = () => {
    setConfirmationModalDelete({ show: false, reference: '' });
    apiDelete('articulo/' + articleUpdate.id + '/').then(r => {
      if (r.error == false) {
        update();
        setRegisterResponse({ show: true, error: r.error, message: 'Artículo eliminado correctamente' });
        setTimeout(() => {
          setRegisterResponse({ show: false, error: false, message: '' });
        }, 3000);
      } else {
        let message = 'Error en:';
        Object.entries(r.res).forEach(([key, errors], index) => {
          message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
            .toString()
            .toLowerCase()})`;
        });
        setRegisterResponse({ show: true, error: r.error, message });
      }
    });
  };

  const SetNewArticle = () => {
    setArticleUpdate({ ...articleUpdate, name: '', type: '' });
    setToggleRegisterArticle(true);
  };

  const SetCloseArticle = () => {
    update();
    setToggleRegisterArticle(false);
  };

  return (
    <div className={style.body}>
      <div className={style.background}>
        {toggleRegisterArticle && (
          <div className={style.overlay}>
            <div className={style.body}>
              <div className={style.center}>
                <RegisterArticle
                  isUser={false}
                  toggle={SetCloseArticle}
                  isEdit={articleUpdate.name !== ''}
                  articleName={articleUpdate.name !== '' ? articleUpdate.name : null}
                  articleType={articleUpdate.type !== '' ? articleUpdate.type : null}
                  articleId={articleUpdate.id !== '' ? articleUpdate.id : null}
                />
              </div>
            </div>
          </div>
        )}
        <ConfirmationModal
          isOpen={confirmationModalDelete.show}
          title="Eliminar artículo"
          message={`eliminar el artículo ${confirmationModalDelete.reference}`}
          onConfirm={() => ConfirmDeleteArticle()}
          onClose={() => setConfirmationModalDelete({ show: false, reference: '' })}
        />
        {registerResponse.show && (
          <Container>
            <Row>
              <Col>
                {registerResponse.error ? (
                  <Error message={registerResponse.message} />
                ) : (
                  <Success message={registerResponse.message} />
                )}
              </Col>
            </Row>
          </Container>
        )}
        <Container fluid>
          <Row className={style.right}>
            <Col className={style.titleAdmin}>Artículos</Col>
            <Col sm={{ size: 'auto' }}>
              <Button size="sm" className={style.buttonAdd} onClick={() => SetNewArticle()}>
                {' '}
                <i className="fas fa-plus" />
                {'  '}Nuevo Artículo
              </Button>
            </Col>
          </Row>
        </Container>
        {articles.length > 0 ? (
          <div>
            {articles.map((item, indexItem) => {
              return (
                <Container key={indexItem} fluid>
                  <Row>
                    <Col className={style.articleTitle}>{item.name}</Col>
                  </Row>
                  <div className={style.cardArticleItem}>
                    {item.array.map((article, index) => {
                      return (
                        <div key={index}>
                          {index >= linesCount ? (
                            <Collapse isOpen={toggleCollapse[item.type]}>
                              <AdminArticleItem key={index} item={article} id={index} deleteArticle={DeleteArticle} />
                            </Collapse>
                          ) : (
                            <AdminArticleItem key={index} item={article} id={index} deleteArticle={DeleteArticle} />
                          )}
                          {index == linesCount && linesCount !== item.array.length && !toggleCollapse[item.type] && (
                            <Button
                              className={style.buttonCollapse}
                              id="toggler"
                              style={{ marginBottom: '1rem' }}
                              onClick={() =>
                                setToggleCollapse({ ...toggleCollapse, [item.type]: !toggleCollapse[item.type] })
                              }
                            >
                              Ver Más <i className="fas fa-angle-down"></i>
                            </Button>
                          )}
                          {linesCount !== item.array.length &&
                            item.array.length == index + 1 &&
                            toggleCollapse[item.type] && (
                              <Button
                                className={style.buttonCollapse}
                                id="toggler"
                                style={{ marginBottom: '1rem' }}
                                onClick={() =>
                                  setToggleCollapse({ ...toggleCollapse, [item.type]: !toggleCollapse[item.type] })
                                }
                              >
                                Ocultar
                              </Button>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </Container>
              );
            })}
          </div>
        ) : (
          <div>No hay artículos registrados</div>
        )}
      </div>
    </div>
  );
};

export default AdminArticles;

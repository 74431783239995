import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import style from './style.module.scss';
import { useHistory } from 'react-router-dom';

const AdminRequestArticleListItem = ({ item, id, confirmRequest }) => {
  const history = useHistory();
  return (
    <div className={id > 0 ? style.borderTop : undefined}>
      <Container className={style.articleItem} fluid>
        <Row xs="1" sm="1" md="3" lg="3">
          <Col className={style.alignLeft}>
            <div className={style.infoContainer}>
              <div className={style.selectItem}>{item.name}</div>
            </div>
          </Col>
          <Col className={style.alignLeft}>
            <div className={style.infoContainer}>
              <div className={style.selectItem}>{item.type}</div>
            </div>
          </Col>
          <Col className={style.alignButtons} md="12">
            <div className={style.buttonDisplay}>
              <div className={style.buttonWidth}>
                <Button
                  type="submit"
                  className={style.buttonAdd}
                  sm={{ size: 'auto' }}
                  onClick={() => confirmRequest(item.id, item.name, true)}
                >
                  Aceptar
                </Button>
              </div>
              &nbsp;
              <div className={style.buttonWidth}>
                <Button
                  className={style.buttonReject}
                  sm={{ size: 'auto' }}
                  onClick={() => confirmRequest(item.id, item.name, false)}
                >
                  Rechazar
                </Button>

                {'  '}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminRequestArticleListItem;

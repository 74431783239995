import { React, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import style from './style.module.scss';

const ImageConfirmationModal = ({ imageApprovalInfo, setImageApprovalInfo, sendApproval }) => {
  return (
    <Modal
      size="sm"
      isOpen={imageApprovalInfo.showModal}
      toggle={() => setImageApprovalInfo({ aprove: imageApprovalInfo.aprove, showModal: !imageApprovalInfo.showModal })}
      centered
    >
      <ModalHeader className={style.imageModalHeader}>
        <h5>{imageApprovalInfo.aprove ? 'Aprobar Foto' : 'Rechazar Foto'}</h5>
      </ModalHeader>
      <ModalBody className={style.imageModalBody}>
        ¿Está seguro que desea {imageApprovalInfo.aprove ? 'aprobar' : 'rechazar'} esta foto?
      </ModalBody>
      <ModalFooter className={style.imageModalFooter}>
        <Button className={style.ButtonPrimary} onClick={sendApproval} style={{ width: '100%' }}>
          Confirmar
        </Button>
        <Button
          className={style.ButtonSecondary}
          onClick={() =>
            setImageApprovalInfo({ aprove: imageApprovalInfo.aprove, showModal: !imageApprovalInfo.showModal })
          }
          style={{ width: '100%' }}
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageConfirmationModal;

import React, { useState } from 'react';
import { CardBody, Card, Row, Col, FormGroup, Input, Button, Select } from 'reactstrap';
import { Districts, SearchCategoryType } from '../Utils/UtilsData';
import style from './style.module.scss';

const SearchBar = ({ GardenFilter }) => {
  const [filters, setFilters] = useState({ district: '', categoryType: '', search: '' });

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className={style.center}>
      <Card className={style.card}>
        <CardBody>
          <Row className={style.title}>
            <Col className={style.left}>Busca lo que precises</Col>
          </Row>
          <Row>
            <Col md={3} className={style.marginCol}>
              {' '}
              <FormGroup>
                <select
                  className={style.select}
                  name="district"
                  id="district"
                  onChange={e => onChange(e.target.name, e.target.value)}
                >
                  <option key={'default'} value={''}>
                    {' '}
                    Ubicación{' '}
                  </option>
                  {Districts.map((district, i) => {
                    return (
                      <option key={i} className={style.selectOption} value={district.value}>
                        {district.label}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
            <Col md={3} className={style.marginCol}>
              {' '}
              <FormGroup>
                <select
                  className={style.select}
                  name="categoryType"
                  id="categoryType"
                  onChange={e => onChange(e.target.name, e.target.value)}
                >
                  <option key={'default'} value={''}>
                    {' '}
                    Categoría{' '}
                  </option>
                  {SearchCategoryType.map((category, i) => {
                    return (
                      <option key={i} className={style.selectOption} value={category.value}>
                        {category.label}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
            <Col md={4} className={style.marginCol}>
              {' '}
              <FormGroup>
                <Input
                  className={style.input}
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Buscar"
                  onChange={e => onChange(e.target.name, e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={2} className={style.marginCol}>
              {' '}
              <Button className={style.ButtonPrimary} onClick={() => GardenFilter(filters)}>
                Buscar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default SearchBar;

import React from 'react';
import { Row, Col, Container, CardBody, Card } from 'reactstrap';
import style from './style.module.scss';

const GardenItem = ({ title, items, toggle, canEdit }) => {
  return (
    <Container fluid={true} className={style.card}>
      <Row className={style.titleItem}>
        <Col className={style.left}>{title}</Col>
        {canEdit && (
          <Col className={style.edit} onClick={() => toggle(title, items, true)}>
            <i className="fas fa-pen" />
          </Col>
        )}
      </Row>
      <Row md={3} sm={1} xs={1} lg={3}>
        {items.map((item, i) => {
          return (
            <Col className={style.styleItem} key={i}>
              <div className={item.isAccepted ? style.styleAccepted : style.styleNotAccepted}>{item.label}</div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default GardenItem;

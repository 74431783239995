import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, TabPane, TabContent, NavItem, Nav, NavLink, Button, Container, Spinner } from 'reactstrap';
import MapView from '../Maps/Maps';
import GardenItem from './GardenItem';
import { Districts } from '../Utils/UtilsData';
import GardenListInfo from './GardenListInfo';
import style from './style.module.scss';
import { useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../services/services';
import {
  GetLabelGardenType,
  GetLabelArticleType,
  IsUserLogged,
  IsSameUserLogged,
  IsAdministratorUserLogged,
  IsStaffUserLogged,
} from '../Utils/UtilFunctions';
import AddDeleteArticle from '../Article/AddDeleteArticle';
import GardenUserItem from './GardenUserItem';
import GardenUserRequestItem from './GardenUserRequestItem';
import Success from '../Utils/Success';
import Error from '../Utils/Error';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import ModalImage from '../Utils/ModalImage';
import GalleryLayout from '../Layout/GalleryLayout';
import AddImage from '../Image/AddImage';
import { Redirect } from 'react-router-dom';

const info = {
  name: '',
  type: '',
  location: '',
  lat: 0,
  long: 0,
  telefono: '',
  email: '',
  nets: [],
  description: '',
  gardenArticles: [],
  instituteName: '',
  inactiveOnHolidays: false,
  isPublic: false,
  hasPublicContact: false,
  hasPublicLocation: true,
  acceptsNewMembers: false,
  district: '',
  status: '',
};

const GardenInfo = () => {
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [gardenInfo, setGardenInfo] = useState(info);
  const [articleInfo, setArticleInfo] = useState({ title: '', items: [{ value: 2, label: 'semilla1' }] });
  const [popUpArticle, setPopUpArticle] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [hasEditingPermission, setHasEditingPermission] = useState(false);
  const [isColaborator, setIsColaborator] = useState(null);
  const { id } = useParams();
  const [gardenUser, setGardenUser] = useState([]);
  const [gardenRequestUser, setGardenRequestUser] = useState([]);
  const [registerResponseUserRequest, setRegisterResponseUserRequest] = useState({
    show: false,
    error: false,
    message: '',
  });
  const [showImage, setShowImage] = useState(false);
  const [imagePopUp, setImagePopUp] = useState({ url: null, imageId: null });
  const [loadingImages, setLoadingImages] = useState(true);
  const [images, setImages] = useState([]);
  const [popUpAddImage, setPopUpAddImage] = useState(false);
  const [redirectHome, setRedirectHome] = useState(false);

  useEffect(() => {
    apiGet('huerta/' + id + '/', {}).then(r => {
      if (r.error == false) {
        var articles = [];
        var articleExists = false;
        if (r.res.articulo && r.res.articulo.length > 0) {
          r.res.articulo.forEach(garArticle => {
            articleExists = false;
            articles.forEach(article => {
              if (article.type == garArticle.tipo_articulo) {
                articleExists = true;
                article.array.push({
                  value: garArticle.id,
                  label: garArticle.nombre,
                  isAccepted: garArticle.fue_aceptado,
                });
              }
            });
            if (!articleExists) {
              articles.push({
                type: garArticle.tipo_articulo,
                array: [{ value: garArticle.id, label: garArticle.nombre, isAccepted: garArticle.fue_aceptado }],
              });
            }
          });
        }
        const district = r.res.ubicacion?.barrio ? Districts.filter(district => district.value === r.res.ubicacion.barrio)[0].label : '';
        if ((!IsAdministratorUserLogged() && r.res.estado !== 'ARCHIVADA') || IsAdministratorUserLogged()) {
          setGardenInfo({
            ...info,
            id: r.res.id,
            name: r.res.nombre,
            tel: r.res.referente?.telefono,
            email: r.res.referente?.email,
            type: GetLabelGardenType(r.res.tipo),
            description: r.res.descripcion,
            cantCol: r.res.cant_colaboradores,
            location: r.res.ubicacion?.direccion,
            lat: r.res.ubicacion?.latitud,
            long: r.res.ubicacion?.longitud,
            gardenArticles: articles,
            instituteName: r.res.nombre_institucion,
            inactiveOnHolidays: r.res.inactiva_en_vacaciones,
            isPublic: r.res.es_publica,
            hasPublicContact: r.res.contacto_es_publico,
            hasPublicLocation: r.res.ubicacion?.es_publica,
            acceptsNewMembers: r.res.acepta_nuevos_miembros,
            district,
            status: r.res.estado,
            updateDate: new Date(r.res.updated_at),
            nets: r.res.redes_names,
            accepted: r.res.fue_aceptada,
            existPendingDelete: r.res.pendiente_baja,
          });
        } else {
          setRedirectHome(true);
        }
        var userPermission =
          IsUserLogged() && (IsSameUserLogged(r.res.referente?.email) || IsAdministratorUserLogged());
        setHasEditingPermission(userPermission);
      } else {
        setRegisterResponse({ show: true, error: r.error, message: 'No es posible acceder a esta página' });
      }
    });
  }, [popUpArticle]);

  useEffect(() => {
    if (IsUserLogged()) {
      apiGet(`usuario/mishuertas/colaborador`, {}).then(result => {
        if (result.error == false) {
          setIsColaborator(gardenInfo.cantCol > 0 ? result.res.findIndex(g => g.id === gardenInfo.id) > -1 : false);
        }
      });
    }
  }, [gardenInfo.cantCol, gardenInfo.id]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const articleSetUp = () => {
    setArticleInfo({ title: '', items: [] });
    setPopUpArticle(true);
  };

  const articleEdit = (title, items, show) => {
    setArticleInfo({ title: title, items: items });
    setPopUpArticle(show);
  };

  const update = useCallback(() => {
    apiGet('huerta/' + id + '/', {}).then(r => {
      if (r.error == false) {
        const district = r.res.ubicacion?.barrio ? Districts.filter(district => district.value === r.res.ubicacion.barrio)[0].label : '';
        setGardenInfo({
          ...info,
          id: r.res.id,
          name: r.res.nombre,
          tel: r.res.referente?.telefono,
          email: r.res.referente?.email,
          type: GetLabelGardenType(r.res.tipo),
          description: r.res.descripcion,
          cantCol: r.res.cant_colaboradores,
          location: r.res.ubicacion?.direccion,
          lat: r.res.ubicacion?.latitud,
          long: r.res.ubicacion?.longitud,
          instituteName: r.res.nombre_institucion,
          inactiveOnHolidays: r.res.inactiva_en_vacaciones,
          isPublic: r.res.es_publica,
          hasPublicContact: r.res.contacto_es_publico,
          hasPublicLocation: r.res.ubicacion?.es_publica,
          acceptsNewMembers: r.res.acepta_nuevos_miembros,
          district,
          status: r.res.estado,
          accepted: r.res.fue_aceptada,
          nets: r.res.redes_names,
          existPendingDelete: r.res.pendiente_baja,
        });
      }
    });
  }, []);

  const updateRequestCollaborators = useCallback(() => {
    apiGet('huerta/solicitud/' + id, {})
      .then(r => {
        if (r.error == false) {
          var requestUser = r.res.map(item => ({
            id: item.id,
            email: item.user_mail,
          }));
          setGardenRequestUser(requestUser);
        }
      })
      .finally(toggle('3'));
  }, []);

  const handleUserTab = () => {
    apiGet('huerta/' + id + '/colaboradores', {})
      .then(r => {
        if (r.error == false) {
          var gardenUserMap = [];
          r.res.map(item => {
            return gardenUserMap.push({
              id: item.id,
              name: item.first_name,
              email: item.email,
              phone: item.telefono,
            });
          });
          const gardenUserMapSorted = gardenUserMap?.sort((a, b) => (a.name > b.name ? 1 : -1));
          setGardenUser(gardenUserMapSorted);
        }
      })
      .finally(() => {
        toggle('2');
      });
  };

  const RequestUserAccept = (requestId, accept) => {
    apiPost('huerta/solicitud/' + id, {
      aceptar: accept,
      solicitud_id: requestId,
    }).then(r => {
      if (r.error == false) {
        setRegisterResponseUserRequest({
          show: true,
          error: r.error,
          message: `Se actualizó el usuario colaborador correctamente`,
        });
        updateRequestCollaborators();
        update();
      } else {
        var message = '';
        Object.entries(r.res).forEach(([key, errors], index) => {
          message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
            .toString()
            .toLowerCase()})`;
        });
        setRegisterResponseUserRequest({ show: true, error: r.error, message: message });
      }
    });
  };

  const deleteUser = useCallback(
    userId => {
      apiPost('huerta/quitarcolaborador/', {
        huerta_id: gardenInfo.id,
        usuario_id: userId,
      }).then(r => handleUserTab());
    },
    [gardenInfo],
  );

  const loadImages = () => {
    apiGet('huerta/' + id + '/imagenes').then(response => {
      if (!response.error) {
        setImages(response.res.urls);
      }
    });
    setLoadingImages(false);
  };

  const loadImage = imgId => {
    apiGet('huerta/imagenes/' + imgId).then(response => {
      if (!response.error) {
        console.log('image popup: ' + response.res.url + ' ' + imgId);
        setImagePopUp({ url: response.res.url, imageId: imgId });
        setShowImage(true);
      }
    });
  };

  const toggleAddImage = () => {
    setPopUpAddImage(!popUpAddImage);
  };
  const toggleShowImage = () => {
    setShowImage(!showImage);
  };

  if (registerResponse.show) {
    return (
      <div className={style.body}>
        <div className={style.background}>
          <Row>
            <Col>
              {registerResponse.error ? (
                <Error message={registerResponse.message} />
              ) : (
                <Success message={registerResponse.message} />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  if (redirectHome) {
    return <Redirect to="/home" />;
  }
  return (
    <div className={style.body}>
      <div className={style.background}>
        {showImage && (
          <ModalImage
            toggle={toggleShowImage}
            image={imagePopUp}
            hasDeletePermission={hasEditingPermission}
            gardenId={id}
            reload={loadImages}
          />
        )}
        {popUpArticle && <AddDeleteArticle article={articleInfo} gardenId={id} toggle={setPopUpArticle} />}
        {popUpAddImage && <AddImage gardenId={id} toggle={toggleAddImage} reload={loadImages} />}
        <Row className={style.title}>
          <Col className={style.left}>{gardenInfo.name}</Col>
          <Col className={style.status}>{gardenInfo.status}</Col>
        </Row>
        <Row className={style.subtitle}>
          <Col className={style.left}>{gardenInfo.type}</Col>
        </Row>
        <Row>
          <Col md="5">
            {gardenInfo.location ? <MapView gardens={[gardenInfo]} /> : 'La ubicacion de la huerta es privada'}
          </Col>
          <Col>
            <GardenListInfo garden={gardenInfo} update={update} />
          </Col>
        </Row>
        <Nav tabs className={style.nav}>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? style.navLinkActive : style.navLink}
              onClick={() => {
                toggle('1');
              }}
            >
              Artículos
            </NavLink>
          </NavItem>
          {(hasEditingPermission || IsStaffUserLogged()) && (
            <>
              <NavItem>
                <NavLink className={activeTab === '2' ? style.navLinkActive : style.navLink} onClick={handleUserTab}>
                  <div>Colaboradores{'  '}</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === '3' ? style.navLinkActive : style.navLink}
                  onClick={updateRequestCollaborators}
                >
                  <div>Solicitudes de colaboradores{'  '}</div>
                </NavLink>
              </NavItem>
            </>
          )}
          <NavItem>
            <NavLink
              className={activeTab === '4' ? style.navLinkActive : style.navLink}
              onClick={() => {
                setLoadingImages(true);
                toggle('4');
                loadImages();
              }}
            >
              Fotos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {gardenInfo.gardenArticles.length > 0 ? (
              <Container fluid={true}>
                <Row lg={3} md={3} sm={1} xs={1} className={style.paddingTop}>
                  {gardenInfo.gardenArticles.map((article, i) => {
                    return (
                      <Col key={i} className={style.colFormat}>
                        <GardenItem
                          title={GetLabelArticleType(article.type)}
                          items={article.array}
                          toggle={articleEdit}
                          canEdit={hasEditingPermission || IsStaffUserLogged()}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            ) : (
              <div className={style.paddingTop}>No tiene artículos</div>
            )}
          </TabPane>
          <TabPane tabId="2">
            <Row className={style.rowUsers}>
              {gardenUser.length > 0 ? (
                gardenUser.map((item, i) => {
                  return <GardenUserItem user={item} isRequest={false} deleteUser={deleteUser} key={i} />;
                })
              ) : (
                <div>No tiene colaboradores</div>
              )}
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row className={style.rowUsers}>
              {gardenRequestUser.length > 0 ? (
                <>
                  {registerResponseUserRequest.show && (
                    <Container>
                      <Row>
                        <Col>
                          {registerResponseUserRequest.error ? (
                            <Error message={registerResponseUserRequest.message} />
                          ) : (
                            <Success message={registerResponseUserRequest.message} />
                          )}
                        </Col>
                      </Row>
                    </Container>
                  )}
                  {gardenRequestUser.map((item, i) => {
                    return <GardenUserRequestItem user={item} key={i} requestUserAccept={RequestUserAccept} />;
                  })}
                </>
              ) : (
                <div>No tiene solicitudes de colaboradores</div>
              )}
            </Row>
          </TabPane>
          <TabPane tabId="4">
            {loadingImages ? (
              <Spinner style={{ marginTop: '2rem' }} color="secondary" />
            ) : images.length > 0 ? (
              <GalleryLayout>
                {images.map((item, i) => (
                  <img className={style.gardenImage} src={item.url} key={item.id} onClick={() => loadImage(item.id)} />
                ))}
              </GalleryLayout>
            ) : (
              <Container>
                <Row className={style.rowUsers}>
                  <div>No tiene fotos</div>
                </Row>
              </Container>
            )}
            {(hasEditingPermission || isColaborator) && (
              <div style={{ display: 'flex' }}>
                <Button className={style.ButtonPrimary} onClick={() => toggleAddImage()}>
                  <i className="fas fa-plus" /> Agregar foto
                </Button>
              </div>
            )}
          </TabPane>
        </TabContent>

        {(hasEditingPermission || IsStaffUserLogged() || isColaborator) && activeTab === '1' && (
          <Row className={style.buttonArticle}>
            <Col className={style.left}>
              <Button className={style.ButtonPrimary} onClick={() => articleSetUp()}>
                <i className="fas fa-plus" />
                {'  '}Agregar artículos
              </Button>
            </Col>
          </Row>
        )}
        <Row className={style.updateDate}>
          {gardenInfo.updateDate && (
            <div>
              <span>Última actualización: </span>
              <span>
                {gardenInfo.updateDate.toLocaleString(undefined, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                hs
              </span>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default GardenInfo;

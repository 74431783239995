import React, { useEffect, useState, useMemo } from 'react';
import {
  Row,
  Col,
  Button,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavLink,
  Tooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import style from './style.module.scss';
import { apiGet } from '../../services/services';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmationModal from './../ConfirmationModal/ConfirmationModal';
import { UserType } from '../Utils/UtilsData';

const Header = () => {
  const history = useHistory();
  const [user, setUser] = useState();
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [currentWindow, setCurrentWindow] = useState('Home');

  const tooltipToogle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    var user = localStorage.getItem('currentUser');
    setUser(user);
  }, [localStorage.getItem('currentUser')]);

  const userPermission = useMemo(() => {
    var superUser = localStorage.getItem('is_superuser');
    var staffUser = localStorage.getItem('is_staff');
    if (superUser == 'true') {
      return UserType.ADMIN;
    } else {
      if (staffUser == 'true') {
        return UserType.STAFF;
      } else return UserType.USER;
    }
  }, [localStorage.getItem('is_superuser'), localStorage.getItem('is_staff')]);

  const onConfirmLogout = () => {
    apiGet('usuario/salir/', {}).then(r => {
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('is_staff');
      localStorage.removeItem('is_superuser');
      history.push('/home');
      handleCurrentWindow('Home');
    });
  };

  const toggle = () => setIsOpen(!isOpen);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/home')) {
      setCurrentWindow('Home');
    } else if (location.pathname.includes('/garden')) {
      setCurrentWindow('Garden');
    } else if (location.pathname.includes('/userevent')) {
      setCurrentWindow('Events');
    } else if (location.pathname.includes('/help')) {
      setCurrentWindow('Help');
    } else if (location.pathname.includes('/about')) {
      setCurrentWindow('About');
    } else if (location.pathname.includes('/user') || location.pathname.includes('/adminhome')) {
      setCurrentWindow('Profile');
    }
  }, [location]);

  const handleCurrentWindow = window => {
    setCurrentWindow(window);
    setIsOpen(false);
  };

  return (
    <Navbar color="light" light expand="md" className={isOpen ? style.container : style.containerClosed}>
      <NavbarBrand className={style.link} href="/#/home">
        <span className={style.appTitle}>SIHO</span>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} className={style.navbarMenu}>
        Menú
      </NavbarToggler>
      <ConfirmationModal
        isOpen={showSignOutModal}
        title="Cerrar Sesión"
        message={`cerrar sesión de ${user}`}
        onConfirm={() => onConfirmLogout()}
        onClose={() => setShowSignOutModal(false)}
      />
      <Collapse isOpen={isOpen} navbar className={style.collapse}>
        <Row className={`mr-auto ${style.rowHeader}`} navbar>
          <Col className={style.menuItem}>
            <NavLink
              className={style.link}
              href="/#/home"
              onClick={() => {
                handleCurrentWindow('Home');
              }}
            >
              {' '}
              <div className={currentWindow === 'Home' && style.active}>Inicio</div>
            </NavLink>
          </Col>
          <Col className={style.menuItem}>
            <NavLink className={style.link} href="/#/garden" onClick={() => handleCurrentWindow('Garden')}>
              {' '}
              <div className={currentWindow === 'Garden' && style.active}>Huertas</div>
            </NavLink>
          </Col>
          <Col className={style.menuItem}>
            <NavLink className={style.link} href="/#/userevent" onClick={() => handleCurrentWindow('Events')}>
              {' '}
              <div className={currentWindow === 'Events' && style.active}>Eventos</div>
            </NavLink>
          </Col>
          <Col className={style.menuItemAbout}>
            <NavLink className={style.link} href="/#/about" onClick={() => handleCurrentWindow('About')}>
              {' '}
              <div className={currentWindow === 'About' && style.active}> Quiénes somos</div>
            </NavLink>
          </Col>
          <Col className={style.menuItem}>
            <NavLink className={style.link} href="/#/help" onClick={() => handleCurrentWindow('Help')}>
              <div className={currentWindow === 'Help' && style.active}> Ayuda</div>
            </NavLink>
          </Col>
          {user ? (
            <React.Fragment>
              <Col className={style.menuItem} id="tooltipUser">
                {userPermission == UserType.USER && (
                  <NavLink className={style.link} href="/#/user" onClick={() => setCurrentWindow('Profile')}>
                    <div className={style.userTitle}>
                      <Tooltip placement="bottom" isOpen={tooltipOpen} target="tooltipUser" toggle={tooltipToogle}>
                        {user}
                      </Tooltip>
                      <div className={currentWindow === 'Profile' && style.active}>Mi Usuario</div>
                    </div>
                  </NavLink>
                )}
                {(userPermission == UserType.ADMIN || userPermission == UserType.STAFF) && (
                  <div className={currentWindow === 'Profile' && style.active}>
                    {' '}
                    <UncontrolledDropdown setActiveFromChild>
                      <DropdownToggle className={style.toggle} color="transparent" caret>
                        Mi Usuario
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>{user}</DropdownItem>
                        <DropdownItem href="/#/user" onClick={() => setIsOpen(false)}>
                          Mi Perfil
                        </DropdownItem>
                        <DropdownItem href="/#/adminhome" onClick={() => setIsOpen(false)}>
                          Panel de Administrador
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                )}
              </Col>
              <Col className={style.buttonHeader}>
                <Button className={style.ButtonPrimary} onClick={() => setShowSignOutModal(true)}>
                  Salir
                </Button>
              </Col>
            </React.Fragment>
          ) : (
            <Col className={style.buttonHeader}>
              <NavLink href="/#/login">
                {' '}
                <Button className={style.ButtonPrimary} onClick={() => setIsOpen(false)}>
                  Ingresar
                </Button>
              </NavLink>
            </Col>
          )}
        </Row>
      </Collapse>
    </Navbar>
  );
};

export default Header;

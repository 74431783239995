import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Button, Input, TabPane, TabContent, NavItem, Nav, NavLink, Container } from 'reactstrap';
import style from './style.module.scss';
import { apiDelete, apiGet, apiPatch } from '../../services/services';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import UserGardenItem from './UserGardenItem';
import ConfirmationModal from './../ConfirmationModal/ConfirmationModal';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import CreatedEvents from './CreatedEvents';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { IsSameUserLogged, IsAdministratorUserLogged } from '../Utils/UtilFunctions';

const info = {
  id: '',
  email: '',
  first_name: '',
  telefono: '',
  password: '',
};

const UserInfo = () => {
  const { id } = useParams();

  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });

  const [userInfo, setUserInfo] = useState(info);
  const [editedUserInfo, setEditedUserInfo] = useState(info);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const [userGardenRef, setUserGardenRef] = useState([]);
  const [userGardenCol, setUserGardenCol] = useState([]);

  const [activeTab, setActiveTab] = useState('1');
  const [isEditingInfo, setIsEditingInfo] = useState(false);

  const [isAdminOwnUser, setIsAdminOwnUser] = useState(false);
  const [redirectHome, setRedirectHome] = useState(false);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (id) {
      apiGet('usuario/' + id + '/', {}).then(r => {
        if (r.error == false) {
          const initializedUser = {
            ...info,
            id: r.res.id,
            email: r.res.email,
            first_name: r.res.first_name,
            telefono: r.res.telefono,
            is_superuser: r.res.is_superuser,
            is_staff: r.res.is_staff,
          };
          setUserInfo(initializedUser);
          setEditedUserInfo({
            id: initializedUser.id,
            email: initializedUser.email,
            first_name: initializedUser.first_name,
            telefono: initializedUser.telefono,
          });
          setIsAdminOwnUser(IsSameUserLogged(initializedUser.email) && IsAdministratorUserLogged());
        }
      });
    } else {
      apiGet('usuario/miperfil/', {}).then(r => {
        if (r.error == false) {
          const initializedUser = {
            ...info,
            id: r.res.id,
            email: r.res.email,
            first_name: r.res.first_name,
            telefono: r.res.telefono,
            is_superuser: r.res.is_superuser,
            is_staff: r.res.is_staff,
          };
          setUserInfo(initializedUser);
          setEditedUserInfo({
            id: initializedUser.id,
            email: initializedUser.email,
            first_name: initializedUser.first_name,
            telefono: initializedUser.telefono,
          });
          setIsAdminOwnUser(IsAdministratorUserLogged());
        }
      });
    }
    apiGet('usuario/mishuertas/referente', {}).then(r => {
      if (r.error == false) {
        const gardenRefMap = r.res.map(item => {
          return {
            id: item.id,
            name: item.nombre,
            nets: item.redes_names,
            location: item.ubicacion.direccion,
          };
        });
        setUserGardenRef(gardenRefMap);
      }
    });
    apiGet('usuario/mishuertas/colaborador', {}).then(r => {
      if (r.error == false) {
        const gardenColMap = r.res.map(item => {
          return {
            id: item.id,
            name: item.nombre,
            nets: item.redes_names,
            location: item.ubicacion.direccion,
          };
        });
        setUserGardenCol(gardenColMap);
      }
    });
  }, []);

  const onChange = (name, value) => {
    setEditedUserInfo({ ...editedUserInfo, [name]: value });
  };

  const onConfirmSave = useCallback(() => {
    const body = {};
    Object.entries(editedUserInfo).forEach(([key, value]) => {
      if (userInfo[key] !== value) {
        body[key] = value;
      }
    });
    const requiredFieldsCompleted = editedUserInfo.email !== '';
    if (!requiredFieldsCompleted) {
      setRegisterResponse({ show: true, error: true, message: 'No se completaron todos los campos obligatorios' });
    } else {
      if (isNaN(Number(editedUserInfo.telefono))) {
        setRegisterResponse({ show: true, error: true, message: 'El teléfono ingresado debe ser un número' });
      } else {
        apiPatch(`usuario/${userInfo.id}/`, body).then(r => {
          if (r.error == false) {
            setUserInfo({ ...editedUserInfo });
            localStorage.setItem('currentUser', JSON.stringify(editedUserInfo.email));
            setRegisterResponse({
              show: true,
              error: r.error,
              message: `Usuario modificado correctamente. ${
                body.email ? `El correo electrónico para iniciar sesión fue cambiado a ${body.email}` : ''
              }`,
            });
          } else {
            let message = 'Error en:';
            Object.entries(r.res).forEach(([key, errors], index) => {
              message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
                .toString()
                .toLowerCase()})`;
            });
            setRegisterResponse({ show: true, error: r.error, message });
          }
        });
      }
    }
    setIsEditingInfo(false);
    window.scrollTo(0, 0);
  }, [editedUserInfo]);

  const onCancel = () => {
    setEditedUserInfo(userInfo);
    setIsEditingInfo(false);
  };

  const onConfirmDelete = () => {
    setShowDeleteConfirmation(false);
    apiDelete('usuario/' + userInfo.id + '/').then(r => {
      if (r.error) {
        setRegisterResponse({
          show: true,
          error: true,
          message: 'No se pudo eliminar el usuario',
        });
      } else {
        setRegisterResponse({
          show: true,
          error: false,
          message: 'Usuario eliminado correctamente',
        });
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('is_staff');
        localStorage.removeItem('is_superuser');
        setTimeout(() => {
          setRedirectHome(true);
        }, 2000);
      }
    });
  };

  const editModeButtons = useMemo(
    () => (
      <div className={style.buttonsContainer}>
        <Button className={style.ButtonPrimary} size="lg" onClick={() => setShowSaveConfirmation(true)}>
          Guardar
        </Button>
        <Button className={style.ButtonSecondary} size="lg" onClick={onCancel}>
          Cancelar
        </Button>
      </div>
    ),
    [editedUserInfo],
  );

  const viewModeButtons = useMemo(
    () => (
      <div className={style.buttonsContainer}>
        <Button className={style.editButton} size="lg" onClick={() => setIsEditingInfo(true)}>
          Modificar
        </Button>
        {!isAdminOwnUser && (
          <Button className={style.deleteButton} size="lg" onClick={() => setShowDeleteConfirmation(true)}>
            Eliminar
          </Button>
        )}
      </div>
    ),
    [isAdminOwnUser],
  );

  const { innerWidth: width, innerHeight: height } = window;
  if (!IsAdministratorUserLogged() && id) {
    return <Redirect to="/home" />;
  }
  if (redirectHome) {
    return <Redirect to="/home" />;
  }
  return (
    <div className={style.background}>
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        title="Borrar Usuario"
        message={`borrar la cuenta de usuario ${userInfo.email}`}
        onConfirm={onConfirmDelete}
        onClose={() => setShowDeleteConfirmation(false)}
      />
      <ConfirmationModal
        isOpen={showSaveConfirmation}
        title="Modificar Usuario"
        message={`modificar datos de usuario ${userInfo.email}`}
        onConfirm={onConfirmSave}
        onClose={() => setShowSaveConfirmation(false)}
      />
      {registerResponse.show && (
        <Row>
          <Col>
            {registerResponse.error ? (
              <Error message={registerResponse.message} />
            ) : (
              <Success message={registerResponse.message} />
            )}
          </Col>
        </Row>
      )}
      <Row className={style.title}>
        <Col className={style.titleCol}>
          <div className={style.name}>
            <i className="fas fa-user"></i>
            {!isEditingInfo && (userInfo.first_name.length > 0 ? userInfo.first_name : 'Usuario')}
            {isEditingInfo && (
              <Input
                type="first_name"
                name="first_name"
                id="first_name"
                placeholder="nombre de usuario"
                value={editedUserInfo.first_name}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
            )}
          </div>
          <Row className={style.subtitle}>
            <Col className={style.left}>
              {userInfo.is_superuser ? 'Administrador' : userInfo.is_staff ? 'Moderador' : ''}
            </Col>
          </Row>
        </Col>
        {width >= 768 && (
          <Col className={style.right}>
            {!isEditingInfo && viewModeButtons}
            {isEditingInfo && editModeButtons}
          </Col>
        )}
      </Row>
      <Row>
        <Col className={style.col}>
          <div className={`${style.iconContainer} ${style.inputContainer}`}>
            <i className={'fas fa-envelope'} />
            {isEditingInfo ? (
              <Input
                type="email"
                name="email"
                id="email"
                invalid={editedUserInfo.email === ''}
                placeholder="correo electronico"
                value={editedUserInfo.email}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
            ) : (
              userInfo.email
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={style.col}>
          <div className={`${style.iconContainer} ${style.inputContainer}`}>
            <i className="fas fa-phone" />
            {isEditingInfo ? (
              <Input
                type="text"
                name="telefono"
                id="telefono"
                placeholder="Telefono"
                value={editedUserInfo.telefono}
                invalid={isNaN(Number(editedUserInfo.telefono))}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
            ) : (
              userInfo.telefono
            )}
          </div>
        </Col>
      </Row>
      {isEditingInfo && (
        <Row>
          <Col className={style.col}>
            <div className={`${style.iconContainer} ${style.inputContainer}`}>
              <i className="fa fa-key" aria-hidden="true"></i>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Nueva contraseña"
                value={editedUserInfo.password}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
            </div>
          </Col>
        </Row>
      )}

      {width < 768 && (
        <Col className={style.right}>
          {!isEditingInfo && viewModeButtons}
          {isEditingInfo && editModeButtons}
        </Col>
      )}
      {!id && (
        <>
          <Nav tabs className={style.nav}>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? style.navLinkActive : style.navLink}
                onClick={() => {
                  toggle('1');
                }}
              >
                Referente en:
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? style.navLinkActive : style.navLink}
                active={activeTab === '2'}
                onClick={() => {
                  toggle('2');
                }}
              >
                Colaborador en:
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '3' ? style.navLinkActive : style.navLink}
                active={activeTab === '3'}
                onClick={() => {
                  toggle('3');
                }}
              >
                Eventos creados:
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {userGardenRef.length > 0 ? (
                <Container fluid>
                  <Row lg={4} md={2} sm={1} xs={1}>
                    {userGardenRef.map((gardenRefItem, i) => {
                      return (
                        <Col key={i} className={style.cardReferenceItem}>
                          <UserGardenItem item={gardenRefItem} />
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              ) : (
                <div className={style.emptyList}> No es referente en ninguna huerta </div>
              )}
            </TabPane>
            <TabPane tabId="2">
              {userGardenCol.length > 0 ? (
                <Container fluid>
                  <Row lg={4} md={2} sm={1} xs={1}>
                    {userGardenCol.map((gardenColItem, i) => {
                      return (
                        <Col key={i} className={style.cardColItem}>
                          <UserGardenItem item={gardenColItem} />
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              ) : (
                <div className={style.emptyList}> No es colaborador en ninguna huerta </div>
              )}
            </TabPane>
            <TabPane tabId="3">
              <CreatedEvents />
            </TabPane>
          </TabContent>
        </>
      )}
    </div>
  );
};

export default UserInfo;

import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, HashRouter, Redirect } from 'react-router-dom';
import MapView from './components/Maps/Maps';
import Login from './components/Login/Login';
import RegisterUser from './components/RegisterUser/RegisterUser';
import SearchBar from './components/SearchBar/SearchBar';
import GardenInfo from './components/GardenInfo/GardenInfo';
import Home from './components/Home/Home';
import RegisterGarden from './components/RegisterGarden/RegisterGarden';
import MainLayout from './components/Layout/MainLayout';
import NotFound from './components/Utils/NotFound';
import Garden from './components/Garden/Garden';
import UserInfo from './components/UserInfo/UserInfo';
import RegisterArticle from './components/Article/RegisterArticle';
import RegisterEvent from './components/Events/RegisterEvent';
import UserEvents from './components/Events/UserEvents/UserEvents';
import AdminEvents from './components/Events/AdminEvents/AdminEvents';
import AdminHome from './components/Home/Admin/AdminHome';
import PasswordReset from './components/RestorePassword/PasswordReset';
import PasswordConfirm from './components/RestorePassword/PasswordConfirm';
import Help from './components/Help/Help';
import AboutUs from './components/AboutUs/AboutUs';

function AppRoute({ ...params }) {
  return (
    <>
      <MainLayout>
        <Route {...params} />
      </MainLayout>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <AppRoute exact path="/" component={Home} />
          <AppRoute exact path="/maps" component={MapView} />
          <AppRoute exact path="/login" component={Login} />
          <AppRoute exact path="/register" component={RegisterUser} />
          <AppRoute exact path="/search" component={SearchBar} />
          <AppRoute exact path="/home" component={Home} />
          <AppRoute exact path="/garden/:id" component={GardenInfo} />
          <AppRoute exact path="/user" component={UserInfo} />
          <AppRoute exact path="/garden" component={Garden} />
          <AppRoute exact path="/registergarden" component={RegisterGarden} />
          <AppRoute exact path="/registerarticle" component={RegisterArticle} />
          <AppRoute exact path="/registerevent" component={RegisterEvent} />
          <AppRoute exact path="/userevent" component={UserEvents} />
          <AppRoute exact path="/adminevent" component={AdminEvents} />
          <AppRoute exact path="/adminhome" component={AdminHome} />
          <AppRoute exact path="/admin/user/:id" component={UserInfo} />
          <AppRoute exact path="/password-reset" component={PasswordReset} />
          <AppRoute exact path="/password-confirm/:token" component={PasswordConfirm} />
          <AppRoute exact path="/help" component={Help} />
          <AppRoute exact path="/about" component={AboutUs} />
          <Route component={NotFound} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;

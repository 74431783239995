import React, { useState } from 'react';
import { Nav, NavItem, Row, TabContent, TabPane, NavLink } from 'reactstrap';
import AdminRequestArticleList from '../Article/AdminRequestArticleList';
import AdminRequestGardenList from '../Garden/AdminRequestGardenList';
import style from './style.module.scss';

const AdminRequest = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Nav tabs className={style.nav}>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? style.navLinkActive : style.navLink}
            onClick={() => {
              toggle('1');
            }}
          >
            Huertas
          </NavLink>
        </NavItem>
        {localStorage.getItem('is_superuser') === 'true' && (
          <NavItem>
            <NavLink
              className={activeTab === '2' ? style.navLinkActive : style.navLink}
              active={activeTab === '2'}
              onClick={() => {
                toggle('2');
              }}
            >
              Artículos
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <AdminRequestGardenList />
        </TabPane>
        {localStorage.getItem('is_superuser') === 'true' && (
          <TabPane tabId="2">
            <AdminRequestArticleList />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default AdminRequest;

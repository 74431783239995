import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  Spinner,
  Tooltip,
  FormFeedback,
} from 'reactstrap';
import { apiPatch, apiPost } from '../../services/services';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import style from './style.module.scss';
import { Districts } from '../Utils/UtilsData';
import { GetLabelDistrict, GetValueDistrictLabel, DateToInput, TimeToInput } from '../Utils/UtilFunctions';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';

const RegisterEvent = ({ update = false, item = {}, closePopUp }) => {
  const [registerEvent, setRegisterEvent] = useState({
    id: 0,
    name: '',
    description: '',
    district: Districts[0].label,
    address: '',
    isContest: false,
    date: undefined,
    time: undefined,
    confirmLoading: false,
  });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [redirect, setRedirect] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({ isContest: false });
  const [descriptionCount, setDescriptionCount] = useState(0);

  const toggle = (name, value) => {
    setTooltipOpen({ ...tooltipOpen, [name]: value });
  };

  useEffect(() => {
    if (update) {
      var fechaHora = new Date(item.fecha_hora);
      setRegisterEvent({
        ...registerEvent,
        id: item.id,
        name: item.nombre,
        description: item.descripcion,
        district: item.ubicacion?.barrio ? GetLabelDistrict(item.ubicacion.barrio) : Districts[0].label,
        address: item.ubicacion?.direccion,
        isContest: item.es_concurso,
        date: DateToInput(fechaHora),
        time: TimeToInput(fechaHora),
      });
      setDescriptionCount(item.descripcion.length)
    }
  }, []);

  const submitForm = e => {
    e.preventDefault();
    var isValid = registerEvent.name !== '' && registerEvent.address !== '' && registerEvent.date !== undefined;
    if (!isValid) {
      setRegisterResponse({ show: true, error: true, message: 'No se completaron todos los campos obligatorios' });
    } else {
      if (update) {
        var body = {
          //id: registerEvent.id,
          nombre: registerEvent.name,
          descripcion: registerEvent.description,
          direccion: registerEvent.address,
          ubicacion: {
            direccion: registerEvent.address,
            barrio: GetValueDistrictLabel(registerEvent.district),
          },
          es_concurso: registerEvent.isContest,
          fecha_hora: registerEvent.date + ' ' + registerEvent.time,
        };
        apiPatch(`evento/${registerEvent.id}/`, body).then(r => {
          if (r.error == false) {
            setRegisterResponse({ show: true, error: r.error, message: 'Evento modificado correctamente' });
            setTimeout(() => {
              closePopUp({ show: false });
            }, 2000);
          } else {
            setRegisterResponse({ show: true, error: r.error, message: JSON.stringify(r.res) });
          }
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setRegisterEvent({ ...registerEvent, confirmLoading: true });
        var body = {
          nombre: registerEvent.name,
          descripcion: registerEvent.description,
          ubicacion: {
            direccion: registerEvent.address,
            latitud: 0,
            longitud: 0,
            barrio: GetValueDistrictLabel(registerEvent.district),
          },
          es_concurso: registerEvent.isContest,
          fecha_hora: registerEvent.date + ' ' + registerEvent.time,
        };

        apiPost('evento/', body).then(r => {
          setRegisterEvent({ ...registerEvent, confirmLoading: false });
          if (r.error == false) {
            setRegisterResponse({ show: true, error: r.error, message: 'Evento creado correctamente' });
            setTimeout(() => {
              setRedirect(true);
            }, 3000);
          } else {
            let message = 'Error en:';
            Object.entries(r.res).forEach(([key, errors], index) => {
              message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
                .toString()
                .toLowerCase()})`;
            });
            setRegisterResponse({ show: true, error: r.error, message });
          }
        });
      }
    }
    !update && window.scrollTo(0, 0);
  };

  const onChange = (name, value) => {
    setRegisterEvent({ ...registerEvent, [name]: value });
  };

  const recalculate = e => {
    setDescriptionCount(e.target.value.length);
  };

  if (redirect) {
    return <Redirect to="/userevent" />;
  }
  return (
    <div className={update ? style.bodyEdit : style.body}>
      <div className={style.center}>
        <Card className={style.card}>
          <CardBody>
            {registerResponse.show && (
              <Row>
                <Col>
                  {registerResponse.error ? (
                    <Error message={registerResponse.message} />
                  ) : (
                    <Success message={registerResponse.message} />
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col className={style.title}>{update ? 'Modificar evento' : 'Crear un Evento'}</Col>
            </Row>
            <Row className={style.center}>
              <Col md="10">
                <Form
                  className={style.Form}
                  onSubmit={e => submitForm(e)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                >
                  <FormGroup className={style.FormGroup}>
                    <div className={style.labelContainer}>
                      <Label for="name">Nombre </Label>
                      <Label className={style.required}>(obligatorio)</Label>
                    </div>
                    <Input
                      type="name"
                      name="name"
                      id="name"
                      placeholder="Nombre del evento"
                      value={registerEvent.name}
                      invalid={registerEvent.name === ''}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                    <FormFeedback className={style.invalidFeedback}>Se debe ingresar un nombre válido</FormFeedback>
                  </FormGroup>
                  <FormGroup className={style.FormGroup}>
                    <Label for="institution" className={style.description}>
                      <span>Descripción</span>
                      <span style={{fontWeight: 'normal'}}>{descriptionCount}/1023</span>
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder="Ejemplo: Les traemos la invitación a nuestra próxima jornada huertera…"
                      value={registerEvent.description}
                      onChange={e => {
                        onChange(e.target.name, e.target.value);
                        recalculate(e);
                      }}
                    />
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <FormGroup className={style.FormGroup}>
                        <Label for="institution">Barrio</Label>
                        <Col>
                          <Input
                            type="select"
                            name="district"
                            id="district"
                            placeholder="Barrio"
                            value={registerEvent.district}
                            onChange={e => onChange(e.target.name, e.target.value)}
                          >
                            {Districts.map((district, i) => {
                              return <option key={i}>{district.label}</option>;
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className={style.FormGroup}>
                        <div className={style.labelContainer}>
                          <Label for="name">Calle y Número </Label>
                          <Label className={style.required}>(obligatorio)</Label>
                        </div>
                        <Input
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Calle"
                          value={registerEvent.address}
                          invalid={registerEvent.address === ''}
                          onChange={e => onChange(e.target.name, e.target.value)}
                        />
                        <FormFeedback className={style.invalidFeedback}>
                          Se debe ingresar una calle y número válido
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup className={style.FormGroup}>
                        <div className={style.labelContainer}>
                          <Label for="name">Fecha </Label>
                          <Label className={style.required}>(obligatorio)</Label>
                        </div>
                        <Input
                          type="date"
                          name="date"
                          id="date"
                          placeholder="Fecha"
                          value={registerEvent.date}
                          invalid={registerEvent.date === undefined}
                          onChange={e => onChange(e.target.name, e.target.value)}
                        />
                        <FormFeedback className={style.invalidFeedback}>Se debe ingresar una fecha válida</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className={style.FormGroup}>
                        <div className={style.labelContainer}>
                          <Label for="name">Hora </Label>
                          <Label className={style.required}>(obligatorio)</Label>
                        </div>
                        <Input
                          type="time"
                          name="time"
                          id="time"
                          placeholder="Hora"
                          value={registerEvent.time}
                          invalid={registerEvent.time === undefined}
                          onChange={e => onChange(e.target.name, e.target.value)}
                        />
                        <FormFeedback className={style.invalidFeedback}>Se debe ingresar una fecha válida</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={11} sm={9} xs={9} className={style.checkboxItems}>
                      <FormGroup check className={style.FormGroup}>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="isContest"
                            id="isContest"
                            value={registerEvent.isContest}
                            onChange={e => onChange(e.target.name, e.target.checked)}
                            checked={registerEvent.isContest}
                            className={style.checkbox}
                          />{' '}
                          <div className={style.inputCheckbox}>Es concurso</div>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={1} sm={2} xs={2} id="DisabledAutoHideCollaborators" className={style.tooltipQuestion}>
                      <i className="far fa-question-circle"></i>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen.isContest}
                        autohide={false}
                        target="DisabledAutoHideCollaborators"
                        toggle={() => toggle('isContest', !tooltipOpen.isContest)}
                      >
                        Si selecciona esta opción su evento es un concurso.
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row className={style.Button}>
                    <Col md="6" xs="6">
                      {update ? (
                        <Button className={style.ButtonSecondary} size="lg" onClick={() => closePopUp({ show: false })}>
                          Cancelar
                        </Button>
                      ) : (
                        <Link className={style.link} to="/home">
                          <Button className={style.ButtonSecondary} size="lg">
                            Cancelar
                          </Button>
                        </Link>
                      )}
                    </Col>
                    <Col md="6" xs="6">
                      <Button type="submit" className={style.ButtonPrimary} size="lg">
                        {update ? 'Guardar' : 'Confirmar'}{' '}
                        {registerEvent.confirmLoading ? <Spinner color="success" size="sm" /> : null}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default RegisterEvent;

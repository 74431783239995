import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import style from './style.module.scss';

const MainLayout = ({ children }) => {
  if (children) {
    return (
      <div className={style.containerLayout}>
        <Header />
        <div className={style.mainLayout}>{children}</div>
        <Footer />
      </div>
    );
  } else {
    return <div>Pagina no encontrada</div>;
  }
};

export default MainLayout;

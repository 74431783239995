import React from 'react';
import L from 'leaflet';
import { TileLayer, Marker, Popup, MapContainer, useMapEvents } from 'react-leaflet';
import style from './style.module.scss';
import icon from 'leaflet/dist/images/marker-icon.png';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

const MapSelectPosition = ({ message, position, setPosition, confirmPosition, cancelPosition }) => {
  let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });
  const positionDefault = [-34.843789, -56.254669];

  function LocationMarker() {
    const map = useMapEvents({
      click(e) {
        if (e) {
          map.locate();
          if (e.latlng) {
            setPosition(e.latlng);
          }
        }
      },
    });

    if (position !== null && position.lat && position.lng) {
      return (
        <Marker position={position}>
          <Popup>Seleccionado</Popup>
        </Marker>
      );
    } else {
      return <div></div>;
    }
  }
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div className={style.overlay}>
      <div className={style.body}>
        <div className={style.center}>
          <Card className={style.card}>
            <CardBody>
              <div className={style.title}>{message}</div>
              Por favor, selecciona la ubicación de la huerta en el mapa:
              <MapContainer
                className={style.leafletErrorContainer}
                center={positionDefault}
                zoom={12}
                scrollWheelZoom={true}
                onClick={() => selectPosition()}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
              </MapContainer>
              <Row className={style.Button}>
                <Col md="6" xs="6">
                  <Button onClick={() => cancelPosition()} className={style.ButtonSecondary} size="md">
                    Cancelar
                  </Button>
                </Col>
                <Col md="6" xs="6">
                  <Button onClick={() => confirmPosition()} className={style.ButtonPrimary} size="md">
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MapSelectPosition;

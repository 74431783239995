import React, { useEffect, useState } from 'react';
import { CardBody, Card, Row, Col, Container } from 'reactstrap';
import ListItem from './ListItem';
import style from './style.module.scss';
import { GetLabelSearchCategoryType, GetLabelDistrict } from '../Utils/UtilFunctions';
import GardenInfoItem from '../Garden/GardenInfoItem';

const List = ({ info, filters }) => {
  const [filter, setFilter] = useState({ show: false, message: '' });

  useEffect(() => {
    var messageFilter = '';
    console.log('data');
    if (filters.search || filters.categoryType || filters.district) {
      if (filters.search !== '') {
        messageFilter = filters.search;
      }
      if (filters.categoryType !== '') {
        if (messageFilter !== '') {
          messageFilter += ', ' + GetLabelSearchCategoryType(filters.categoryType);
        } else {
          messageFilter = GetLabelSearchCategoryType(filters.categoryType);
        }
      }
      if (filters.district !== '') {
        if (messageFilter !== '') {
          messageFilter += ', ' + GetLabelDistrict(filters.district);
        } else {
          messageFilter = GetLabelDistrict(filters.district);
        }
      }
      setFilter({ show: true, message: messageFilter });
    } else {
      setFilter({ show: false, message: messageFilter });
    }
  }, [filters]);

  return (
    <div className={style.center}>
      {(filter.show || info.length > 0) && (
        <Card className={style.card}>
          <CardBody>
            {filter.show && (
              <div>
                <div className={style.filter}>
                  Huertas que coinciden con: {`"`}
                  {filter.message}
                  {`"`}
                </div>
                {info.length === 0 && <div>No hay resultados de la búsqueda</div>}
              </div>
            )}
            <Container fluid={true}>
              <Row lg={2} md={1} sm={1} xs={1}>
                {info.map((item, i) => {
                  return (
                    <Col key={i} className={style.marginCol}>
                      <ListItem {...item} />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default List;

import React, { useCallback, useState } from 'react';
import style from './style.module.scss';
import { Row, Col, Container } from 'reactstrap';

import { apiDelete } from '../../services/services';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const AdminNetItem = ({ item, messageFromItem, refresh, hasBorder }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onDeleteNet = useCallback(() => {
    apiDelete(`huerta/red/${item.id}`, {}).then(r => {
      if (r.error == false) {
        messageFromItem({
          show: true,
          error: r.error,
          message: `La red '${item.nombre}' se eliminó correctamente`,
        });
        refresh();
      } else {
        const message = `Error en ${Object.keys(r.res)[0]}: ${Object.values(r.res)[0]}`;
        messageFromItem({ show: true, error: r.error, message });
      }
    });
  }, [item.id]);

  return (
    <div className={hasBorder ? style.borderTop : ''}>
      <Container className={style.cardItem} fluid>
        <ConfirmationModal
          isOpen={showDeleteConfirmation}
          title="Eliminar red"
          message={`eliminar la red ${item.nombre}`}
          onConfirm={onDeleteNet}
          onClose={() => setShowDeleteConfirmation(false)}
        />
        <Row>
          <Col className={style.left}>{item.nombre}</Col>
          <Col md="1" sm="2" xs="3">
            <div onClick={() => setShowDeleteConfirmation(true)}>
              <div className={style.delete}>
                <i className="fas fa-trash-alt" />
              </div>
              {'  '}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminNetItem;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Col, Row } from 'reactstrap';
import style from './style.module.scss';

const NotFound = () => (
  <Container className={style.paddingTop}>
    <Row>
      <Col className={style.colCenter}>
        <div className={style.notFoundTitle}>404</div>
        <h2>Página no encontrada</h2>
        <p>Selecciona el botón para redirigirte a la página principal de la aplicación</p>

        <Button className={style.notFoundButton}>
          <Link className={style.link} to="/home">
            Página principal
          </Link>
        </Button>
      </Col>
    </Row>
  </Container>
);

export default NotFound;

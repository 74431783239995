import React, { useEffect, useMemo, useState } from 'react';
import { CardBody, Card, Container, Row, Col } from 'reactstrap';
import style from './style.module.scss';
import AdminRequestGardenListItem from './AdminRequestGardenListItem';
import { apiGet, apiPost } from '../../services/services';
import Success from '../Utils/Success';
import Error from '../Utils/Error';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import AproveImageCard from '../Image/AproveImageCard';
import GalleryLayout from '../Layout/GalleryLayout';
import ImageConfirmationModal from '../Image/ImageConfirmationModal';
import ModalImage from '../Utils/ModalImage';
import { IsAdministratorUserLogged } from '../Utils/UtilFunctions';

const AdminRequestGardenList = () => {
  const [gardenRequestAdd, setGardenRequestAdd] = useState([]);
  const [gardenRequestRemove, setGardenRequestRemove] = useState([]);
  const [gardenRequestImages, setGarderRequestImages] = useState([]);
  const [confirmationModalRequestAdd, setConfirmationModalRequestAdd] = useState({ show: false, reference: '' });
  const [confirmationModalRequestRemove, setConfirmationModalRequestRemove] = useState({ show: false, reference: '' });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [gardenRequested, setGardenRequested] = useState({ id: 0, accept: false });
  const [imageApprovalInfo, setImageApprovalInfo] = useState({ showModal: false });
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModal, setImageModal] = useState();

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    apiGet('huerta/alta', {}).then(r => {
      if (r.error == false) {
        var gardenAddArray = [];
        r.res.map(item => {
          return gardenAddArray.push({
            id: item.id,
            name: item.nombre,
            email: item.referente?.email,
            tel: item.referente?.telefono,
          });
        });
        setGardenRequestAdd(gardenAddArray);
      }
    });
    apiGet('huerta/baja', {}).then(r => {
      if (r.error == false) {
        var gardenRemoveArray = [];
        r.res.map(item => {
          return gardenRemoveArray.push({
            id: item.id,
            name: item.nombre,
            email: item.referente?.email,
            tel: item.referente?.telefono,
          });
        });
        setGardenRequestRemove(gardenRemoveArray);
      }
    });
    if (localStorage.getItem('is_superuser') === 'true') {
      console.log('Cargando imagenes');
      loadPendingImages();
    }
  };

  const loadPendingImages = () => {
    apiGet('huerta/imagenes/aprobar').then(response => {
      if (!response.error) {
        setGarderRequestImages(response.res.urls);
      }
    });
  };

  const ConfirmRequestAdd = (id, name, isAccept) => {
    setGardenRequested({ id: id, accept: isAccept });
    setConfirmationModalRequestAdd({ show: true, reference: name });
  };

  const ConfirmRequestRemove = (id, name, isAccept) => {
    setGardenRequested({ id: id, accept: isAccept });
    setConfirmationModalRequestRemove({ show: true, reference: name });
  };

  const ConfirmImageApproval = (id, aprove) => {
    setImageApprovalInfo({ id: id, aprove: aprove, showModal: true });
  };

  const loadImage = imgId => {
    apiGet('huerta/imagenes/' + imgId).then(response => {
      if (!response.error) {
        setImageModal({ url: response.res.url, imageId: imgId });
        setShowImageModal(true);
      }
    });
  };

  const toggleImageModal = () => {
    setShowImageModal(!showImageModal);
  };

  const sendApproval = () => {
    apiPost('huerta/imagenes/aprobar', { imagen_id: imageApprovalInfo.id, aceptada: imageApprovalInfo.aprove }).then(
      response => {
        if (!response.error) {
          setImageApprovalInfo({ aprove: imageApprovalInfo.aprove, showModal: false });
          loadPendingImages();
        } // TODO: Error
      },
    );
  };

  const GardenSendRequestAdd = () => {
    var body = {
      huerta_id: gardenRequested.id,
      aceptar: gardenRequested.accept,
    };
    var message = gardenRequested.accept ? 'Huerta aceptada correctamente' : 'Huerta rechazada correctamente';
    apiPost('huerta/alta', body).then(r => {
      if (r.error == false) {
        setRegisterResponse({ show: true, error: r.error, message: message });
        update();
        setTimeout(() => {
          setRegisterResponse({ show: false, error: false, message: '' });
        }, 2000);
      } else {
        let message = 'Error en:';
        Object.entries(r.res).forEach(([key, errors], index) => {
          message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
            .toString()
            .toLowerCase()})`;
        });
        setRegisterResponse({ error: r.error, message });
      }
    });
  };

  const GardenSendRequestRemove = () => {
    var body = {
      huerta_id: gardenRequested.id,
      aceptar: gardenRequested.accept,
    };
    var message = gardenRequested.accept ? 'Huerta eliminada correctamente' : 'Eliminación cancelada correctamente';
    apiPost('huerta/baja', body).then(r => {
      if (r.error == false) {
        setRegisterResponse({ show: true, error: r.error, message: message });
        update();
        setTimeout(() => {
          setRegisterResponse({ show: false, error: false, message: '' });
        }, 2000);
      } else {
        let message = 'Error en:';
        Object.entries(r.res).forEach(([key, errors], index) => {
          message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
            .toString()
            .toLowerCase()})`;
        });
        setRegisterResponse({ error: r.error, message });
      }
    });
  };

  const isAdmin = useMemo(() => IsAdministratorUserLogged(),[]);

  return (
    <div className={style.body}>
      <div className={style.background}>
        <ConfirmationModal
          isOpen={confirmationModalRequestAdd.show}
          title={gardenRequested.accept ? 'Confirmar Huerta' : 'Rechazar Huerta'}
          message={`${gardenRequested.accept ? 'confirmar' : 'rechazar'} la solicitud de la huerta ${
            confirmationModalRequestAdd.reference
          }`}
          onConfirm={() => GardenSendRequestAdd()}
          onClose={() => setConfirmationModalRequestAdd({ show: false, reference: '' })}
        />
        <ConfirmationModal
          isOpen={confirmationModalRequestRemove.show}
          title={gardenRequested.accept ? 'Confirmar Baja' : 'Cancelar Baja'}
          message={`${gardenRequested.accept ? 'confirmar' : 'cancelar'} la baja de la huerta ${
            confirmationModalRequestRemove.reference
          }`}
          extraInfo={gardenRequested.accept ? 'Si confirma esta acción, la huerta será archivada' : undefined}
          onConfirm={() => GardenSendRequestRemove()}
          onClose={() => setConfirmationModalRequestRemove({ show: false, reference: '' })}
        />
        <ImageConfirmationModal
          imageApprovalInfo={imageApprovalInfo}
          setImageApprovalInfo={setImageApprovalInfo}
          sendApproval={sendApproval}
        />
        {showImageModal && <ModalImage toggle={toggleImageModal} image={imageModal} hasDeletePermission={false} />}
        {registerResponse.show && (
          <Container>
            <Row>
              <Col>
                {registerResponse.error ? (
                  <Error message={registerResponse.message} />
                ) : (
                  <Success message={registerResponse.message} />
                )}
              </Col>
            </Row>
          </Container>
        )}
        <div className={style.titleDate}>{'Altas'}</div>
        {gardenRequestAdd.length > 0 ? (
          <>
            {' '}
            <Card>
              <CardBody>
                {gardenRequestAdd.map((item, i) => {
                  return (
                    <div key={i}>
                      <AdminRequestGardenListItem id={i} item={item} confirmRequest={ConfirmRequestAdd} />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </>
        ) : (
          <div>No hay solicitudes de alta</div>
        )}
        {isAdmin && (
          <>
            <div className={style.titleDate}>{'Bajas'}</div>
            {gardenRequestRemove.length > 0 ? (
              <>
                {' '}
                <Card>
                  <CardBody>
                    {gardenRequestRemove.map((item, i) => {
                      return (
                        <div key={i}>
                          <AdminRequestGardenListItem id={i} item={item} confirmRequest={ConfirmRequestRemove} />
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              </>
            ) : (
              <div>No hay solicitudes de baja</div>
            )}

            <div className={style.titleDate}>{'Fotos de huertas'}</div>
            {gardenRequestImages.length > 0 ? (
              <>
                {' '}
                <GalleryLayout>
                  {gardenRequestImages.map((item, i) => {
                    return (
                      <div key={item.id}>
                        <AproveImageCard
                          imgId={item.id}
                          imgUrl={item.url}
                          gardenName={item.huerta_name}
                          action={ConfirmImageApproval}
                          loadImage={loadImage}
                        />
                      </div>
                    );
                  })}
                </GalleryLayout>
              </>
            ) : (
              <div>No hay solicitudes de fotos</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminRequestGardenList;

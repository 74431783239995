import React, { useState } from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import style from './style.module.scss';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const GardenUserItem = ({ user, isRequest, deleteUser }) => {
  const [confirmationModalDelete, setConfirmationModalDelete] = useState(false);

  return (
    <>
      <ConfirmationModal
        isOpen={confirmationModalDelete}
        title="Quitar colaborador"
        message={`quitar el usuario colaborador ${user.email}`}
        onConfirm={() => deleteUser(user.id)}
        onClose={() => setConfirmationModalDelete(false)}
      />
      <Container className={style.userItem}>
        <Col md={9}>
          <Row>
            <Col md={4} className={style.userInfoItem}>
              {user.name}
            </Col>
            <Col md={4} className={style.userInfoItem}>
              {user.email}
            </Col>
            <Col md={4} className={style.userInfoItem}>
              {user.phone}
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          {isRequest ? (
            <div className={style.userItemButtons}>
              <Button className={style.buttonAceptar}>Aceptar</Button>
              <Button className={style.buttonRechazar}>Rechazar</Button>
            </div>
          ) : (
            <Button
              className={style.buttonDelete}
              onClick={() => setConfirmationModalDelete(true)}
            >
              Quitar
            </Button>
          )}
        </Col>
      </Container>
    </>
  );
};

export default GardenUserItem;

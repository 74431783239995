import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Row, Col, Button, Input, FormGroup, Form, FormFeedback, Spinner, Label } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import style from './style.module.scss';
import { customSelectStyle } from '../Utils/CustomStyle';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import ConfirmationModal from './../ConfirmationModal/ConfirmationModal';
import {
  GetValueDistrictLabel,
  IsAdministratorUserLogged,
  IsSameUserLogged,
  IsUserLogged,
} from '../Utils/UtilFunctions';
import { Districts, GardenPrivacy, GardenStatus } from '../Utils/UtilsData';
import { apiPatch, apiLocation, apiPost, apiDelete, apiGet } from '../../services/services';
import MapSelectPosition from '../Maps/MapsSelectPosition';

const animatedComponents = makeAnimated();

function parseGardenInfo(garden) {
  return {
    nombre: garden.name,
    descripcion: garden.description,
    ubicacion_publica: garden.hasPublicLocation,
    direccion: garden.location ? garden.location.split(',')[0] : '',
    barrio: garden.district,
    es_publica: garden.isPublic,
    contacto_es_publico: garden.hasPublicContact,
    tel: garden.tel,
    email: garden.email,
    nombre_institucion: garden.instituteName,
    inactiva_en_vacaciones: garden.inactiveOnHolidays,
    longitud: garden.long,
    latitud: garden.lat,
    acepta_nuevos_miembros: garden.acceptsNewMembers,
    cant_colaboradores: garden.cantCol,
    estado: garden.status,
    red: garden.nets,
  };
}

const GardenListInfo = ({ garden, update }) => {
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [requestToJoin, setRequestToJoin] = useState(false);
  const [isColaborator, setIsColaborator] = useState(null);
  const [hasEditingPermission, setHasEditingPermission] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editedGarden, setEditedGarden] = useState({});
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [positionMap, setPositionMap] = useState(null);
  const [mapModal, setMapModal] = useState({ show: false, message: '' });
  const [selectedNets, setSelectedNets] = useState([]);
  const [allNets, setAllNets] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (IsUserLogged()) {
      apiGet('huerta/red/', {}).then(r => {
        if (r.error == false) {
          setAllNets(r.res.map(item => ({ value: item.id, label: item.nombre })));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (allNets.length > 0 && garden.nets.length > 0) {
      var selectedNets = [];
      allNets.map(item => {
        if (garden.nets.includes(item.label)) {
          selectedNets.push(item);
        }
      }),
        setSelectedNets(selectedNets);
    }
  }, [garden.nets, allNets]);

  useEffect(() => {
    setEditedGarden(parseGardenInfo(garden));
    var userPermission = IsUserLogged() && (IsSameUserLogged(garden.email) || IsAdministratorUserLogged());
    setHasEditingPermission(userPermission);
    setRequestToJoin(IsUserLogged() && !IsSameUserLogged(garden.email) && garden.acceptsNewMembers);
    if (IsUserLogged()) {
      apiGet(`usuario/mishuertas/colaborador`, {}).then(r => {
        if (r.error == false) {
          setIsColaborator(garden.cantCol > 0 ? r.res.findIndex(g => g.id === garden.id) > -1 : false);
        }
      });
    }
  }, [garden]);

  const unchangedGarden = useMemo(() => parseGardenInfo(garden), [garden]);

  const onConfirmSave = useCallback(() => {
    confirmEditGarden(false, 0, 0);
  }, [editedGarden, selectedNets]);

  const confirmEditGarden = (positionMap, lat, lng) => {
    const isValid = editedGarden.direccion !== '' && editedGarden.nombre !== '';
    if (!isValid) {
      setRegisterResponse({ show: true, error: true, message: 'No se completaron todos los campos obligatorios' });
      setIsEditing(false);
    } else {
      let body = {};
      setIsLoading(true);
      Object.entries(editedGarden).forEach(([key, data]) => {
        if (editedGarden[key] !== '' && editedGarden[key] !== unchangedGarden[key]) {
          if (
            key === 'direccion' ||
            key === 'ubicacion_publica' ||
            key === 'barrio' ||
            key === 'longitud' ||
            key === 'latitud'
          ) {
            if (key === 'barrio') {
              body = { ...body, ubicacion: { ...body.ubicacion, barrio: GetValueDistrictLabel(data) } };
            } else {
              if (key === 'ubicacion_publica') {
                body = { ...body, ubicacion: { ...body.ubicacion, es_publica: data } };
              }
              body = { ...body, ubicacion: { ...body.ubicacion, [key]: data } };
            }
          } else {
            body[key] = data;
          }
        }
      });
      body.redes = selectedNets.map(item => item.value);
      if (isEditing && editedGarden.direccion !== garden.location && !positionMap) {
        const numdir = editedGarden.direccion.match(/\s\d+$/)[0];
        const streetdir = editedGarden.direccion.substring(0, editedGarden.direccion.length - (numdir.length + 1));

        apiLocation(streetdir, numdir).then(r => {
          if (!r.error) {
            if (r.res.puntoX && r.res.puntoY) {
              body = { ...body, ubicacion: { ...body.ubicacion, latitud: r.res.puntoY.toFixed(6) } };
              body = { ...body, ubicacion: { ...body.ubicacion, longitud: r.res.puntoX.toFixed(6) } };
              apiPatch(`huerta/${garden.id}/`, body).then(r => {
                setIsLoading(false);
                if (r.error == false) {
                  setRegisterResponse({ show: true, error: r.error, message: 'Huerta modificada correctamente' });
                  setIsEditing(false);
                  update();
                } else {
                  setRegisterResponse({ show: true, error: r.error, message: JSON.stringify(r.res.error) });
                }
              });
            } else {
              setMapModal({ show: true, message: 'Ubicación no encontrada' });
              setIsLoading(false);
            }
          } else {
            setRegisterResponse({
              show: true,
              error: r.error,
              message: 'Ha ocurrido un error con la dirección seleccionada',
            });
            setIsLoading(false);
          }
        });
      } else {
        if (positionMap) {
          body = { ...body, ubicacion: { ...body.ubicacion, latitud: lat.toFixed(6) } };
          body = { ...body, ubicacion: { ...body.ubicacion, longitud: lng.toFixed(6) } };
        }
        apiPatch(`huerta/${garden.id}/`, body).then(r => {
          setIsLoading(false);
          if (r.error == false) {
            setRegisterResponse({ show: true, error: r.error, message: 'Huerta modificada correctamente' });
            setIsEditing(false);
            update();
          } else {
            setRegisterResponse({ show: true, error: r.error, message: JSON.stringify(r.res.error) });
          }
        });
      }
    }
    window.scrollTo(0, 0);
  };

  const onConfirmDelete = useCallback(() => {
    apiDelete(`huerta/${garden.id}/`, {}).then(r => {
      if (r.error == false) {
        const message =
          garden.type === 'En casa' || isAdmin
            ? 'La huerta se eliminó correctamente'
            : 'Se envió una solicitud para eliminar la huerta';
        setRegisterResponse({ show: true, error: r.error, message });
        setIsEditing(false);
        update();
      } else {
        setRegisterResponse({ show: true, error: r.error, message: JSON.stringify(r.res) });
      }
      window.scrollTo(0, 0);
    });
  }, [garden]);

  const onChange = (name, value) => {
    setEditedGarden({ ...editedGarden, [name]: value });
  };

  const onCancel = () => {
    setEditedGarden(parseGardenInfo(garden));
    setIsEditing(false);
  };

  const onRequestToJoin = () => {
    apiPost(`huerta/unirse/${garden.id}`, {}).then(r => {
      if (r.error == false) {
        setRegisterResponse({
          show: true,
          error: r.error,
          message: `Se envió una solicitud para ser colaborador en la huerta ${garden.name}`,
        });
      } else {
        setRegisterResponse({ show: true, error: r.error, message: r.res.error });
      }
      window.scrollTo(0, 0);
    });
  };

  const onStopColaborating = () => {
    apiPost(`huerta/abandonar/${garden.id}`, {}).then(r => {
      if (r.error == false) {
        setRegisterResponse({
          show: true,
          error: r.error,
          message: `Ya no es colaborador en la huerta ${garden.name}`,
        });
        setIsColaborator(false);
        update();
      } else {
        setRegisterResponse({ show: true, error: r.error, message: r.res.error });
      }
      window.scrollTo(0, 0);
    });
  };

  const editModeButtons = useMemo(
    () => (
      <Col className={`${style.leftCol} ${style.isEditing}`}>
        <Button className={style.buttonPrimary} onClick={() => setShowSaveConfirmation(true)}>
          Guardar {isLoading ? <Spinner color="success" size="sm" /> : null}
        </Button>
        <Button className={style.ButtonSecondary} onClick={onCancel}>
          Cancelar
        </Button>
      </Col>
    ),
    [editedGarden, isLoading],
  );

  const onConfirmPosition = () => {
    setMapModal({ show: false, message: '' });
    confirmEditGarden(true, positionMap?.lat, positionMap?.lng);
  };

  const onCancelMapModal = () => {
    setMapModal({ ...mapModal, show: false, message: '' });
    setRegisterResponse({
      show: true,
      error: true,
      message: 'Es obligatorio que seleccione una ubicación en el mapa para modificar la huerta',
    });
  };

  const onDeleteGarden = () => {
    if (garden.existPendingDelete) {
      setRegisterResponse({
        show: true,
        error: true,
        message: 'Existe una solicitud pendiente para eliminar la huerta',
      });
    } else {
      setShowDeleteConfirmation(true);
    }
  };

  return (
    <div className={style.backgroundInfo}>
      <ConfirmationModal
        isOpen={showSaveConfirmation}
        title="Modificar huerta"
        message={`modificar datos de la huerta ${garden.name}`}
        onConfirm={onConfirmSave}
        onClose={() => setShowSaveConfirmation(false)}
      />
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        title="Eliminar huerta"
        message={`eliminar la huerta ${garden.name}`}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={onConfirmDelete}
      />
      {mapModal.show && (
        <MapSelectPosition
          message={mapModal.message}
          position={positionMap}
          setPosition={setPositionMap}
          confirmPosition={onConfirmPosition}
          cancelPosition={onCancelMapModal}
        />
      )}
      {registerResponse.show && (
        <Row>
          <Col>
            {registerResponse.error ? (
              <Error message={registerResponse.message} />
            ) : (
              <Success message={registerResponse.message} />
            )}
          </Col>
        </Row>
      )}
      <Row>
        {!isEditing && (
          <>
            <Col>
              {garden.location && garden.district &&(
                <Row>
                  <Col className={style.alignLeft}>
                    <div className={`${style.infoContainer} ${style.location}`}>
                      <div className={style.iconContainer}>
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                      {`${garden.location}, ${garden.district}`}
                    </div>
                  </Col>
                </Row>
              )}
              {garden.tel && (
                <Row>
                  <Col className={style.alignLeft}>
                    <div className={style.infoContainer}>
                      <div className={style.iconContainer}>
                        <i className="fas fa-phone"></i>
                      </div>
                      {garden.tel}
                    </div>
                  </Col>
                </Row>
              )}
              {garden.email && (
                <Row>
                  <Col className={style.alignLeft}>
                    <div className={style.infoContainer}>
                      <div className={style.iconContainer}>
                        <i className="fas fa-envelope"></i>
                      </div>
                      {garden.email}
                    </div>
                  </Col>
                </Row>
              )}
              {garden.type === 'Institucional' && (
                <>
                  <Row>
                    <Col className={style.alignLeft}>
                      Institución: {garden.instituteName} (la huerta se mantendrá{' '}
                      {garden.inactiveOnHolidays ? 'inactiva' : 'activa'} en vacaciones)
                    </Col>
                  </Row>
                </>
              )}
              {garden.nets?.length > 0 && (
                <Row>
                  <Col className={style.alignLeft}>Red: {garden.nets.toString().replaceAll(',', ', ')}</Col>
                </Row>
              )}
            </Col>
            <Col className={style.leftCol}>
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-user"></i>
                </div>
                <div className={style.info}> {garden.cantCol} colaboradores </div>
              </div>
              <div className={style.buttonsContainer}>
                {isColaborator ? (
                  <Button className={style.joinButton} onClick={onStopColaborating}>
                    Dejar de colaborar
                  </Button>
                ) : (
                  requestToJoin && (
                    <Button className={style.joinButton} onClick={onRequestToJoin}>
                      Unirse a la huerta
                    </Button>
                  )
                )}
                {hasEditingPermission && (
                  <>
                    {garden.accepted && (
                      <Button
                        className={style.modifyButton}
                        onClick={() => {
                          setIsEditing(true), setRegisterResponse({ show: false, error: false, message: '' });
                        }}
                      >
                        Modificar
                      </Button>
                    )}
                    {garden.status != 'ARCHIVADA' && (
                      <Button className={style.deleteButton} onClick={() => onDeleteGarden()}>
                        Eliminar
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Col>
          </>
        )}
        {isEditing && (
          <Form className={style.Form} onSubmit={e => submitForm(e)}>
            <FormGroup className={style.formGroup}>
              <div className={style.labelContainer}>
                <Label>Cambiar nombre de la huerta </Label>
                <Label className={style.required}>(obligatorio)</Label>
              </div>
              <Input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nuevo nombre"
                value={editedGarden.nombre}
                invalid={editedGarden.nombre === ''}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
              <FormFeedback className={style.invalidFeedback}>Se debe ingresar un nombre para la huerta</FormFeedback>
            </FormGroup>
            <FormGroup className={style.formGroup}>
              <div className={style.labelContainer}>
                <Label>Calle y Número </Label>
                <Label className={style.required}>(obligatorio)</Label>
              </div>
              <Input
                type="text"
                name="direccion"
                id="direccion"
                placeholder="Calle y número"
                value={editedGarden.direccion}
                invalid={editedGarden.direccion === ''}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
              <FormFeedback className={style.invalidFeedback}>Se debe ingresar una calle y número válido</FormFeedback>
            </FormGroup>
            <FormGroup className={style.formGroup}>
              <Label for="institution">Barrio</Label>
              <Col>
                <Input
                  type="select"
                  name="barrio"
                  id="barrio"
                  placeholder="Barrio"
                  value={editedGarden.barrio}
                  onChange={e => onChange(e.target.name, e.target.value)}
                >
                  {Districts.map((district, i) => {
                    return <option key={i}>{district.label}</option>;
                  })}
                </Input>
              </Col>
            </FormGroup>
            {garden.type === 'Institucional' && (
              <>
                <FormGroup className={style.formGroup}>
                  <Label for="institution">Institución</Label>
                  <Input
                    type="text"
                    name="nombre_institucion"
                    id="nombre_institucion"
                    placeholder="Nombre de la institución"
                    value={editedGarden.nombre_institucion}
                    invalid={editedGarden.nombre_institucion === ''}
                    onChange={e => onChange(e.target.name, e.target.value)}
                  />
                  <FormFeedback className={style.invalidFeedback}>
                    Se debe ingresar el nombre de la institución{' '}
                  </FormFeedback>
                </FormGroup>
                <FormGroup check className={style.formGroup}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="inactiva_en_vacaciones"
                      id="inactiva_en_vacaciones"
                      checked={editedGarden.inactiva_en_vacaciones}
                      onChange={e => onChange(e.target.name, e.target.checked)}
                      className={style.inputCheckbox}
                    />
                    <div className={style.inputCheckbox}>La huerta estará inactiva en vacaciones</div>
                  </Label>
                </FormGroup>
              </>
            )}
            <FormGroup tag="fieldset" className={style.formGroup}>
              <Row>
                <Label>Privacidad de la huerta</Label>
                {GardenPrivacy.map((privacy, i) => {
                  return (
                    <Col key={i} xs="12" sm="6" md="3">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="es_publica"
                            id={privacy.value}
                            checked={
                              (editedGarden.es_publica && privacy.value === 'public') ||
                              (!editedGarden.es_publica && privacy.value !== 'public')
                            }
                            onChange={e => onChange(e.target.name, privacy.value === 'public')}
                            className={style.checkbox}
                          />{' '}
                          {privacy.label}
                        </Label>
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            </FormGroup>
            <FormGroup tag="fieldset" className={style.formGroup}>
              <Row>
                <Label>Estado de huerta </Label>
                {GardenStatus.map((type, i) => {
                  return (
                    <Col className={style.radio} key={i} xs="12" sm="4" md="3">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="estado"
                            id={type.value}
                            checked={type.value === editedGarden.estado}
                            onChange={e => onChange(e.target.name, type.value)}
                            className={style.checkbox}
                          />{' '}
                          {type.label}
                        </Label>
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            </FormGroup>
            <FormGroup check className={style.formGroup}>
              <Label check>
                <Input
                  type="checkbox"
                  name="ubicacion_publica"
                  id="ubicacion_publica"
                  checked={editedGarden.ubicacion_publica}
                  onChange={e => onChange(e.target.name, e.target.checked)}
                  className={(style.inputCheckbox, style.checkbox)}
                />
                <div className={style.inputCheckbox}>
                  Quiero que la ubicación de mi huerta sea pública para todos los usuarios.
                </div>
              </Label>
            </FormGroup>
            <FormGroup check className={style.formGroup}>
              <Label check>
                <Input
                  type="checkbox"
                  name="contacto_es_publico"
                  id="contacto_es_publico"
                  checked={editedGarden.contacto_es_publico}
                  onChange={e => onChange(e.target.name, e.target.checked)}
                  className={(style.inputCheckbox, style.checkbox)}
                />
                <div className={style.inputCheckbox}>
                  Quiero que la forma de contacto de mi huerta sea pública para todos los usuarios.
                </div>
              </Label>
            </FormGroup>
            <FormGroup check className={style.formGroup}>
              <Label check>
                <Input
                  type="checkbox"
                  name="acepta_nuevos_miembros"
                  id="acepta_nuevos_miembros"
                  checked={editedGarden.acepta_nuevos_miembros}
                  onChange={e => onChange(e.target.name, e.target.checked)}
                  className={style.checkbox}
                />
                <div className={style.inputCheckbox}>Se aceptan usuarios colaboradores.</div>
              </Label>
            </FormGroup>
            <Select
              placeholder="Seleccione las redes para vincular a la huerta"
              closeMenuOnSelect={false}
              styles={customSelectStyle}
              components={animatedComponents}
              isMulti
              options={allNets}
              value={selectedNets}
              onChange={setSelectedNets}
            />
          </Form>
        )}
      </Row>

      <Row className={style.line} />
      <Row>
        <Col className={style.alignLeft}>
          {isEditing ? (
            <Input
              type="textarea"
              name="descripcion"
              id="descripcion"
              placeholder="Descripción"
              value={editedGarden.descripcion}
              onChange={e => onChange(e.target.name, e.target.value)}
            />
          ) : (
            <div className={style.description}> {garden.description}</div>
          )}
        </Col>
      </Row>
      {isEditing && editModeButtons}
    </div>
  );
};

export default GardenListInfo;

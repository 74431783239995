import { Districts, GardenType, ArticleType, SearchCategoryType } from './UtilsData';

export function GetLabelGardenType(value) {
  var gardenItem = GardenType.filter(garden => garden.value == value);
  return gardenItem[0]?.label;
}

export function GetLabelSearchCategoryType(value) {
  var categoryItem = SearchCategoryType.filter(category => category.value == value);
  return categoryItem[0].label;
}

export function GetLabelDistrict(value) {
  var districtItem = Districts.filter(district => district.value == value);
  return districtItem[0].label;
}

export function GetValueDistrictLabel(label) {
  var districtItem = Districts.filter(district => district.label === label);
  return districtItem[0].value;
}

export function GetValueArticleLabel(label) {
  var articleItem = ArticleType.filter(article => article.label == label);
  return articleItem[0].value;
}

export function isSameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth();
}

export function GetLabelArticleType(value) {
  var articleItem = ArticleType.filter(article => article.value == value);
  return articleItem[0].label;
}

export function DateToInput(date) {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
}

export function TimeToInput(time) {
  return `${('0' + time.getHours()).slice(-2)}:${('0' + time.getMinutes()).slice(-2)}:${'00'}`;
}

export function IsUserLogged() {
  var userLogged = false;
  var user = localStorage.getItem('currentUser');
  if (user) {
    userLogged = true;
  }
  return userLogged;
}

export function IsAdministratorUserLogged() {
  var administratorLogged = false;
  var user = localStorage.getItem('currentUser');
  if (user && localStorage.getItem('is_superuser') === 'true') {
    administratorLogged = true;
  }
  return administratorLogged;
}

export function IsStaffUserLogged() {
  var staffLogged = false;
  var user = localStorage.getItem('currentUser');
  if (user && localStorage.getItem('is_staff') === 'true') {
    staffLogged = true;
  }
  return staffLogged;
}

export function IsSameUserLogged(userEmail) {
  var sameUser = false;
  var user = localStorage.getItem('currentUser');
  if (user && localStorage.getItem('currentUser').replaceAll('"', '') === userEmail) {
    sameUser = true;
  }
  return sameUser;
}

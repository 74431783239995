import React from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import style from './style.module.scss';

const GardenUserRequestItem = ({ user, requestUserAccept }) => {
  return (
    <Container>
      <Row className={style.userRequestItem}>
        <Col className={style.userInfoItem}>{user.email}</Col>
        <Col>
          <div className={style.userRequestItemButtons}>
            <Button size={'auto'} className={style.ButtonPrimary} onClick={() => requestUserAccept(user.id, true)}>
              Aceptar
            </Button>
            <Button size={'auto'} className={style.ButtonSecondary} onClick={() => requestUserAccept(user.id, false)}>
              Rechazar
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GardenUserRequestItem;

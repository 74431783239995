import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import style from './style.module.scss';
import { useHistory } from 'react-router';
import { IsUserLogged, IsSameUserLogged, IsAdministratorUserLogged, IsStaffUserLogged } from '../Utils/UtilFunctions';

const ListItem = item => {
  const history = useHistory();
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    setHasPermission(
      IsUserLogged() && (IsSameUserLogged(item.email) || IsAdministratorUserLogged() || IsStaffUserLogged()),
    );
  }, [hasPermission, item]);

  return (
    <div className={style.cardItem} onClick={() => history.push('/garden/' + item.id)}>
      <div className={style.borderBottom}>
        <Col className={style.gardenTitle}>{item.name}</Col>
      </div>
      <div className={style.infoGarden}>
        {(item.publicContact || hasPermission) && (
          <Col md="6">
            {item.tel && (
              <Row className={style.itemInfo}>
                {' '}
                <div className={style.iconInfo}>
                  <i className="fas fa-phone" />
                </div>
                {item.tel}
              </Row>
            )}
            <Row className={style.itemInfo}>
              {' '}
              <div className={style.iconInfo}>
                <i className="fas fa-map-marker-alt" />
              </div>
              <span className={style.info}>{item.location}</span>
            </Row>
          </Col>
        )}
        <Col md="6">
          {item.email && (
            <Row className={style.itemInfo}>
              <div className={style.iconInfo}>
                <i className="fas fa-envelope" />
              </div>
              {item.email}
            </Row>
          )}
          {item.nets?.length > 0 && (
            <Row className={style.itemInfo}>
              <div className={style.iconInfo}>Red:</div>
              {item.nets.toString().replaceAll(',', ', ')}
            </Row>
          )}
        </Col>
      </div>
      <Container className={style.container}>
        <Col className={style.colItem}>
          <div className={style.iconInfo}>Contiene:</div>
          <div className={style.contains}>{item.contains}</div>
        </Col>
      </Container>
    </div>
  );
};

export default ListItem;

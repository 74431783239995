import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Container,
} from 'reactstrap';
import { apiPatch, apiPost } from '../../services/services';
import Error from '../Utils/Error';
import { ArticleType } from '../Utils/UtilsData';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import style from './style.module.scss';
import { GetValueArticleLabel, IsUserLogged } from '../Utils/UtilFunctions';
import Success from '../Utils/Success';
import { IsAdministratorUserLogged } from '../Utils/UtilFunctions';

const RegisterArticle = ({ isUser, isEdit = false, toggle, articleType, articleName, gardenId, articleId }) => {
  const [articleForm, setArticleForm] = useState({ name: '', type: ArticleType[0].label, confirmLoading: false });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });

  useEffect(() => {
    if (articleType) {
      console.log(articleType);
      setArticleForm({ ...articleForm, type: articleType, name: articleName });
    }
  }, []);

  const submitForm = e => {
    e.preventDefault();
    var isValid = articleForm.name !== '';
    if (!isValid) {
      setRegisterResponse({ show: true, error: true, message: 'No se completaron todos los campos' });
    } else {
      setArticleForm({ ...articleForm, confirmLoading: true });
      var body = {
        nombre: articleForm.name,
        tipo_articulo: GetValueArticleLabel(articleForm.type),
      };
      if (isUser) {
        body = {
          ...body,
          huerta_id: gardenId,
        };
      }
      if (isEdit) {
        apiPatch(`articulo/${articleId}/`, body).then(r => {
          if (r.error == false) {
            setRegisterResponse({ show: true, error: r.error, message: 'Artículo modificado correctamente' });
            setTimeout(() => {
              toggle(false);
            }, 3000);
          } else {
            let message = 'Error en:';
            Object.entries(r.res).forEach(([key, errors], index) => {
              message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
                .toString()
                .toLowerCase()})`;
            });
            setRegisterResponse({ show: true, error: r.error, message });
          }
        });
      } else {
        apiPost('articulo/', body).then(r => {
          if (r.error == false) {
            var message = 'Artículo registrado correctamente';
            if (isUser) {
              if (!IsAdministratorUserLogged()) {
                message = 'El artículo quedó pendiente de aprobación por parte del administrador';
              }
              setRegisterResponse({
                show: true,
                error: r.error,
                message: message,
              });
              setTimeout(() => {
                toggle(false);
              }, 3000);
            } else {
              setRegisterResponse({ show: true, error: r.error, message: 'Artículo creado correctamente' });
              setTimeout(() => {
                toggle(false);
              }, 3000);
            }
          } else {
            let message = 'Error en:';
            Object.entries(r.res).forEach(([key, errors], index) => {
              if (key === 'nombre_y_tipo_articulo') {
                message = `Error: Ya existe el artículo "${body.nombre}" del tipo "${body.tipo_articulo.toLowerCase()}".`
              } else {
                message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
                .toString()
                .toLowerCase()})`;
              }
            });
            setRegisterResponse({ show: true, error: r.error, message });
          }
        });
      }
      setArticleForm({ ...articleForm, confirmLoading: false });
    }
  };

  const onChange = (name, value) => {
    setArticleForm({ ...articleForm, [name]: value });
  };

  return (
    <div className={style.body}>
      <div className={style.center}>
        <Card className={style.cardRegister}>
          <CardBody>
            {isUser && (
              <div className={style.right} onClick={() => toggle(false)}>
                <i className="fas fa-times"></i>
              </div>
            )}
            {registerResponse.show && (
              <Row>
                <Col>
                  {registerResponse.error ? (
                    <Error message={registerResponse.message} />
                  ) : (
                    <Success message={registerResponse.message} />
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col className={style.titleArticle}>Nuevo Artículo</Col>
            </Row>
            {isUser && !IsAdministratorUserLogged() && (
              <Row>
                <Col className={style.subtitle}>
                  El artículo creado quedará pendiente de aprobación por parte del administrador.
                </Col>
              </Row>
            )}
            <Form className={style.Form} onSubmit={e => submitForm(e)}>
              <FormGroup className={style.FormGroup}>
                <Label for="type" className={style.labelArticle}>
                  Tipo
                </Label>
                <Col>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    placeholder="Seleccionar tipo"
                    value={articleForm.type}
                    disabled={articleType}
                    onChange={e => onChange(e.target.name, e.target.value)}
                  >
                    {ArticleType.map((article, i) => {
                      return <option key={i}>{article.label}</option>;
                    })}
                  </Input>
                </Col>
              </FormGroup>

              <FormGroup className={style.FormGroup}>
                <Label for="name" className={style.labelArticle}>
                  Nombre
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nombre del artículo"
                  value={articleForm.name}
                  invalid={registerResponse.error && articleForm.name == ''}
                  onChange={e => onChange(e.target.name, e.target.value)}
                />
                {registerResponse.error && articleForm.name == '' && (
                  <FormFeedback className={style.invalidFeedback}>Se debe ingresar un nombre</FormFeedback>
                )}
              </FormGroup>

              <Container>
                <Row>
                  <Col>
                    <Button className={style.ButtonSecondary} size="lg" onClick={() => toggle(false)}>
                      Cancelar
                    </Button>
                  </Col>
                  <Col>
                    <Button type="submit" className={style.ButtonPrimary} size="lg">
                      {isEdit ? 'Editar' : 'Confirmar'}{' '}
                      {articleForm.confirmLoading ? <Spinner color="success" size="sm" /> : null}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default RegisterArticle;

import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  CardBody,
  Card,
  Input,
  Button,
  CardTitle,
  CardSubtitle,
  ButtonGroup,
  Label,
  FormGroup,
  FormFeedback,
  Form,
} from 'reactstrap';
import { apiPost } from '../../services/services';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import style from './style.module.scss';

const PasswordConfirm = () => {
  const [form, setForm] = useState({ password: '', repeatPassword: '' });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [redirect, setRedirect] = useState(false);
  const { token } = useParams();

  const submitForm = e => {
    e.preventDefault();
    const requiredFieldsCompleted = form.password !== '' && form.repeatPassword !== '';
    if (!requiredFieldsCompleted) {
      setRegisterResponse({ show: true, error: true, message: 'No se completaron todos los campos obligatorios' });
    } else {
      const isSamePassword = form.password == form.repeatPassword;
      if (!isSamePassword) {
        setRegisterResponse({ show: true, error: true, message: 'Las contraseñas no coinciden' });
      } else {
        var body = {
          token: token,
          password: form.password,
        };
        apiPost('usuario/password-confirm/', body).then(r => {
          if (r.error == false) {
            setRegisterResponse({
              show: true,
              error: r.error,
              message:
                'Contraseña actualizada correctamente, en unos segundos será redirigido a la página de inicio de sesión',
            });
            setTimeout(() => {
              setRedirect(true);
            }, 3000);
          } else {
            let message = 'Error en:';
            Object.entries(r.res).forEach(([key, errors], index) => {
              message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
                .toString()
                .toLowerCase()})`;
            });
            setRegisterResponse({ show: true, error: r.error, message: message });
          }
        });
      }
    }
  };

  const onChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={style.center}>
      <Card className={style.card}>
        <CardBody>
          {registerResponse.show && (
            <div>
              {registerResponse.error ? (
                <Error message={registerResponse.message} />
              ) : (
                <Success message={registerResponse.message} />
              )}
            </div>
          )}
          <CardTitle className={style.title}>Recuperar contraseña</CardTitle>
          <CardSubtitle tag="h6" className={style.subtitle}>
            A continuación ingrese su nueva contraseña
          </CardSubtitle>
          <Form className={style.Form} onSubmit={e => submitForm(e)}>
            <FormGroup className={style.FormGroup}>
              <div className={style.labelContainer}>
                <Label for="password">Contraseña </Label>
                <Label className={style.required}>(obligatorio)</Label>
              </div>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Contraseña"
                value={form.password}
                invalid={form.password === ''}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
              <FormFeedback className={style.invalidFeedback}>Se debe ingresar una contraseña</FormFeedback>
            </FormGroup>
            <FormGroup className={style.FormGroup}>
              <div className={style.labelContainer}>
                <Label for="repeatPassword">Confirmar Contraseña </Label>
                <Label className={style.required}>(obligatorio)</Label>
              </div>
              <Input
                type="password"
                name="repeatPassword"
                id="repeatPassword"
                placeholder="Confirmar contraseña"
                value={form.repeatPassword}
                invalid={form.repeatPassword === ''}
                onChange={e => onChange(e.target.name, e.target.value)}
              />
              <FormFeedback className={style.invalidFeedback}>Se debe ingresar una contraseña</FormFeedback>
            </FormGroup>
            <div className={style.buttonGroup}>
              <Button className={style.ButtonSecondary} onClick={() => setRedirect(true)}>
                Cancelar
              </Button>
              <Button className={style.ButtonPrimary} type="submit">
                Confirmar
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default PasswordConfirm;

import { React, useState } from 'react';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import GalleryLayout from '../Layout/GalleryLayout';
import style from './style.module.scss';

const AproveImageCard = ({ imgId, imgUrl, gardenName, action, loadImage }) => {
  return (
    <div className={style.aproveImageCard}>
      <img src={imgUrl} onClick={() => loadImage(imgId)} />
      <div className={style.title} style={{ overflow: 'clip' }}>
        {gardenName}
      </div>
      <div className={style.buttonContainer}>
        <Button className={style.ButtonPrimary} style={{ padding: '0 30px' }} onClick={() => action(imgId, true)}>
          Aceptar
        </Button>
        <Button className={style.ButtonSecondary} onClick={() => action(imgId, false)}>
          Rechazar
        </Button>
      </div>
    </div>
  );
};

export default AproveImageCard;

import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Container, CardBody, Card } from 'reactstrap';
import style from './style.module.scss';
import { apiGet, apiDelete } from '../../../services/services';
import { isSameDay } from '../../Utils/UtilFunctions';
import AdminEventItem from './AdminEventItem';
import { Redirect } from 'react-router';
import RegisterEvent from '../RegisterEvent';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import Error from '../../Utils/Error';
import Success from '../../Utils/Success';

const AdminEvents = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [editEvent, setEditEvent] = useState({ show: false, item: {} });
  const [registerEvent, setRegisterEvent] = useState(false);
  const [confirmationModalDelete, setConfirmationModalDelete] = useState({
    show: false,
    reference: '',
    eventId: '',
  });
  const [deleteResponse, setDeleteResponse] = useState({
    show: false,
    error: false,
    message: '',
  });
  useEffect(() => {
    apiGet('evento/', {}).then(r => {
      if (r.error == false) {
        setAllEvents(r.res);
        var eventArray = [];
        r.res.map(item => {
          return eventArray.push({
            id: item.id,
            name: item.nombre,
            description: item.descripcion,
            address: item.ubicacion?.direccion,
            date: new Date(item.fecha_hora),
            creator: item.creador_email
          });
        });
        const sortEvents = eventArray.sort((a, b) => b.date - a.date);
        var today = new Date();
        var todayEventsFilter = [];
        var pastEventsFilter = [];
        var futureEventsFilter = [];
        sortEvents.map(e => {
          if (isSameDay(today, e.date)) {
            todayEventsFilter.push(e);
          } else {
            if (today > e.date) {
              pastEventsFilter.push(e);
            } else {
              if (today < e.date) {
                futureEventsFilter.push(e);
              }
            }
          }
        });
        setTodayEvents(todayEventsFilter);
        setPastEvents(pastEventsFilter);
        setFutureEvents(futureEventsFilter);
      }
    });
  }, []);

  const UpdateEvent = id => {
    var eventItem = {};
    allEvents.forEach(event => {
      if (event.id == id) {
        eventItem = event;
      }
    });
    setEditEvent({ ...editEvent, show: true, item: eventItem });
  };

  const deleteEvent = useCallback(id => {
    setConfirmationModalDelete({ show: true, reference: id, eventId: id });
  });

  const confirmDeleteEvent = id => {
    apiDelete('evento/' + id + '/').then(r => {
      if (r.error) {
        setDeleteResponse({
          show: true,
          error: false,
          message: 'No se pudo eliminar el evento',
        });
      } else {
        setDeleteResponse({
          show: true,
          error: false,
          message: 'Evento eliminado correctamente',
        });
        setTimeout(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        }, 1000);
      }
    });
  };

  if (registerEvent) {
    return <Redirect to="/registerevent" />;
  }

  return (
    <div className={style.body}>
      <div className={style.background}>
        {deleteResponse.show && (
          <Row>
            <Col>
              {deleteResponse.error ? (
                <Error message={deleteResponse.message} />
              ) : (
                <Success message={deleteResponse.message} />
              )}
            </Col>
          </Row>
        )}
        <ConfirmationModal
          isOpen={confirmationModalDelete.show}
          title="Eliminar evento"
          message={`eliminar el evento`}
          onConfirm={() => confirmDeleteEvent(confirmationModalDelete.eventId)}
          onClose={() => setConfirmationModalDelete({ show: false, reference: '' })}
        />
        {editEvent.show && (
          <div className={style.overlay}>
            <div className={style.body}>
              <div className={style.center}>
                <RegisterEvent update={true} item={editEvent.item} closePopUp={setEditEvent} />
              </div>
            </div>
          </div>
        )}
        <Container className={style.containerAdmin} fluid>
          <Row className={style.right}>
            <Col className={style.titleAdmin}>Eventos</Col>
            <Col sm={{ size: 'auto' }}>
              <Button size="sm" className={style.buttonAdd} onClick={() => setRegisterEvent(true)}>
                {' '}
                <i className="fas fa-plus" />
                {'  '}Nuevo evento
              </Button>
            </Col>
          </Row>
        </Container>{' '}
        <div className={style.titleDate}>{'Eventos agendados para hoy'}</div>
        {todayEvents.length > 0 ? (
          <>
            <Card>
              <CardBody>
                {todayEvents.map((item, i) => {
                  return (
                    <div key={i}>
                      <AdminEventItem
                        id={i}
                        item={item}
                        updateEvent={UpdateEvent}
                        deleteEvent={deleteEvent}
                        canEdit={true}
                        creator={item.creator}
                      />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </>
        ) : (
          <div>No hay eventos agendados para hoy</div>
        )}{' '}
        <div className={style.titleDate}>{'Eventos pasados'}</div>
        {pastEvents.length > 0 ? (
          <>
            <Card>
              <CardBody>
                {pastEvents.map((item, i) => {
                  return (
                    <div key={i}>
                      <AdminEventItem
                        id={i}
                        item={item}
                        updateEvent={UpdateEvent}
                        deleteEvent={deleteEvent}
                        canEdit={false}
                        creator={item.creator}
                      />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </>
        ) : (
          <div>No hay eventos pasados</div>
        )}{' '}
        <div className={style.titleDate}>{'Eventos futuros'}</div>
        {futureEvents.length > 0 ? (
          <>
            <Card>
              <CardBody>
                {futureEvents.map((item, i) => {
                  return (
                    <div key={i}>
                      <AdminEventItem
                        id={i}
                        item={item}
                        updateEvent={UpdateEvent}
                        deleteEvent={deleteEvent}
                        canEdit={true}
                        creator={item.creator}
                      />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </>
        ) : (
          <div>No hay eventos futuros</div>
        )}
      </div>
    </div>
  );
};

export default AdminEvents;

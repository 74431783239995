import React from "react";
import { Row, Col } from "reactstrap";
import style from "./style.module.scss";

const Help = () => {
  return (
    <div className={style.body}>
      <div className={style.background}>
        <Row className={style.title}>
          <Col className={style.left}>Ayuda</Col>
        </Row>
        <Row className={style.bodyHelp}>
          En caso de necesitar ayuda para utilizar la plataforma contáctenos al
          mail: <a className={style.mail} href='mailto:siho.apex@gmail.com'>siho.apex@gmail.com</a>
        </Row>
      </div>
    </div>
  );
};

export default Help;

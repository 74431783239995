import React, { useEffect, useCallback, useState } from 'react';
import style from './style.module.scss';
import { Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Container, CardBody, Card } from 'reactstrap';

import { apiPost, apiGet } from '../../services/services';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import AdminNetItem from './AdminNetItem';

const AdminNetList = () => {
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [nets, setNets] = useState([]);
  const [newNet, setNewNet] = useState('');

  useEffect(() => {
    apiGet('huerta/red/', {}).then(r => {
      if (r.error == false) {
        setNets([...r.res]);
      }
    });
  }, []);

  const refresh = useCallback(() => {
    apiGet('huerta/red/', {}).then(r => {
      if (r.error == false) {
        setNets([...r.res]);
      }
    });
  }, []);

  const messageFromItem = useCallback(response => {
    setRegisterResponse(response);
    window.scrollTo(0, 0);
  }, []);

  const onCreateNet = useCallback(() => {
    apiPost(`huerta/red/`, { nombre: newNet }).then(r => {
      if (r.error == false) {
        setRegisterResponse({
          show: true,
          error: r.error,
          message: `Se creó la red '${newNet}' correctamente`,
        });
        setNewNet('');
        refresh();
      } else {
        const message = `Error en ${Object.keys(r.res)[0]}: ${Object.values(r.res)[0]}`;
        setRegisterResponse({ show: true, error: r.error, message });
      }
      window.scrollTo(0, 0);
    });
  }, [newNet, refresh]);

  return (
    <div className={style.container}>
      {registerResponse.show && (
        <Row>
          <Col>
            {registerResponse.error ? (
              <Error message={registerResponse.message} />
            ) : (
              <Success message={registerResponse.message} />
            )}
          </Col>
        </Row>
      )}
      <Container className={style.containerAdmin} fluid>
        <Row className={style.right}>
          <Col className={style.titleAdmin}>Redes</Col>
        </Row>
      </Container>
      <div className={style.newNetContainer}>
        <div className={style.newNetLabel}>Agregar una red</div>
        <Row>
          <Col>
            <InputGroup className={style.inputGroup}>
              <Input
                className={style.inputSearch}
                type="text"
                name="search"
                id="nombre"
                placeholder="Nombre de la nueva red"
                value={newNet}
                onChange={e => setNewNet(e.target.value)}
              />
              <InputGroupAddon className={style.addIcon} onClick={onCreateNet}>
                <InputGroupText className={style.buttonAddGroup}>
                  {' '}
                  <i className="fas fa-plus-circle"></i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </div>
      {nets.length > 0 ? (
        <Card>
          <CardBody>
            {' '}
            {nets.map((net, index) => (
              <AdminNetItem
                item={net}
                key={index}
                messageFromItem={messageFromItem}
                refresh={refresh}
                hasBorder={index > 0}
              />
            ))}{' '}
          </CardBody>
        </Card>
      ) : (
        <div className={style.emptyList}> No hay redes </div>
      )}
    </div>
  );
};

export default AdminNetList;

import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { TileLayer, Marker, Popup, MapContainer } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import style from './style.module.scss';

const MapView = ({ gardens }) => {
  let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });
  const positionDefault = [-34.843789, -56.254669];

  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div>
      {gardens.length > 0 && gardens[0].long && gardens[0].lat && (
        <MapContainer className={style.leafletContainer} center={positionDefault} zoom={12} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {gardens.map((garden, i) => {
            return (
              <div key={i}>
                {garden.lat && garden.long && (
                  <Marker key={i} position={[garden.lat, garden.long]}>
                    <Popup>{garden.name}</Popup>
                  </Marker>
                )}
              </div>
            );
          })}
        </MapContainer>
      )}
    </div>
  );
};

export default MapView;

import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import style from './style.module.scss';


const ConfirmationModal = ({ isOpen, title, message, extraInfo, onConfirm, onClose }) => {
  const onClickConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal size="sm" isOpen={isOpen} toggle={onClose}>
      <ModalHeader className={style.modalHeader}> {title} </ModalHeader>
      <ModalBody className={style.modalBody}> 
        ¿Está seguro que desea {message}?
        {extraInfo && (
          <div> {extraInfo} </div>
        )}
      </ModalBody>
      <ModalFooter className={style.modalFooter}>
        <Button className={style.confirmButton} onClick={onClickConfirm}>
          Confirmar
        </Button>
        <Button className={style.cancelButton} onClick={onClose}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;

import React, { useState } from 'react';
import { Card, Col, Container, Row, CardBody, Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import { apiDelete } from '../../services/services';
import style from './style.module.scss';
import Error from './Error.js'
import Success from './Success.js'

const ModalImage = ({ toggle, image, hasDeletePermission, gardenId, reload }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState({
    show: false,
    error: false,
    message: '',
  });


  const deleteImage = () => {
    apiDelete('huerta/' + gardenId + '/imagenes', { imagen_id: image.imageId }).then(response => {
      if (!response.error) {
        setDeleteResponse({
          show: true,
          error: false,
          message: 'Imagen eliminada correctamente.',
        });
        setTimeout(() => {
          reload();
          toggle();
        }, 2000);
      } else {
        setDeleteResponse({
          show: true,
          error: true,
          message: 'Ocurrio un error eliminando la imagen, intente nuevamente.',
        });
      }
    });
  };

  return (
    <Modal
      toggle={toggle}
      isOpen={true}
      size="xl"
      external={
        <Button color="danger" onClick={toggle} style={{ position: 'absolute', right: '15px', top: '15px' }}>
          <i className="fas fa-times" />
        </Button>
      }
    >
      <ModalBody>
        <div className={style.imageModalContainer}>
          <img src={image.url} style={{ width: '100%' }} />
          {hasDeletePermission && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button color="danger" onClick={() => setDeleteModal(true)}>
                Eliminar foto
              </Button>
            </div>
          )}
        </div>
        <Modal size="sm" isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)} centered>
          {deleteResponse.show && (
            <Row>
              <Col>
                {deleteResponse.error ? (
                  <Error message={deleteResponse.message} />
                ) : (
                  <Success message={deleteResponse.message} />
                )}
              </Col>
            </Row>
          )}
          <ModalHeader className={style.imageModalHeader}>
            <h5>Eliminar foto</h5>
          </ModalHeader>
          <ModalBody className={style.imageModalBody}>
            ¿Está seguro que desea eliminar esta foto de forma permanente?
          </ModalBody>
          <ModalFooter className={style.imageModalFooter}>
            <Button className={style.ButtonPrimary} onClick={deleteImage} style={{ width: '100%' }}>
              Eliminar foto
            </Button>
            <Button className={style.ButtonSecondary} onClick={() => setDeleteModal(false)} style={{ width: '100%' }}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </ModalBody>
    </Modal>
  );
};

export default ModalImage;

import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import style from './style.module.scss';

const AdminEventItem = ({ item, id, creator, updateEvent, deleteEvent, canEdit }) => {
  return (
    <div className={id > 0 ? style.borderTop : undefined}>
      <Container className={style.eventItem} fluid>
        <Row xs="1" sm="1" md="4" lg="4">
          <Col className={style.eventTitle}>
            <div className={style.infoContainer}>
              <div className={style.iconContainer}>
                <i className="far fa-calendar-alt" />
              </div>
              &nbsp;
              {item.name}
            </div>
            <div className={style.creatorEmail}>{item.creator}</div>
          </Col>
          <Col className={style.updateDate}>
            {item.date.toLocaleString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
            hs
          </Col>
          <Col>
            {item.address && (
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-map-marker-alt" />
                </div>
                &nbsp;
                {item.address}
              </div>
            )}
          </Col>
          <Col>
            <div className={style.buttonDisplay}>
              {canEdit && (
                <div
                  onClick={() => {
                    updateEvent(item.id);
                  }}
                  className={style.edit}
                >
                  <i className="fas fa-pen"></i>
                  {'  '}
                </div>
              )}
              &nbsp;
              <div
                onClick={() => {
                  deleteEvent(item.id);
                }}
                className={style.delete}
              >
                <i className="fas fa-trash-alt"></i>
                {'  '}
              </div>
            </div>
          </Col>
        </Row>
        {item.description && (
          <Row>
            <Col className={style.itemDescription}>{item.description}</Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default AdminEventItem;

import { config } from '../config/config';

const response = {
  status: 0,
  res: '',
  error: false,
};

function GetHeaders() {
  var configHeader = '';
  if (localStorage.getItem('token') !== null) {
    configHeader = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  } else {
    configHeader = {
      'Content-Type': 'application/json',
    };
  }
  return configHeader;
}

async function apiPost(url = '', data = {}) {
  const r = await fetch(config.baseUrl + url, {
    method: 'POST',
    headers: GetHeaders(),
    body: JSON.stringify(data),
  });
  try {
    const res = await r.json();
    response.res = res;
  } catch (err) {
    response.res = {};
  }
  response.error = !r.ok;

  return response;
}

async function apiPatch(url = '', data = {}) {
  const r = await fetch(config.baseUrl + url, {
    method: 'PATCH',
    headers: GetHeaders(),
    body: JSON.stringify(data),
  });
  try {
    const res = await r.json();
    response.res = res;
  } catch (err) {
    response.res = 'Error en la consulta';
  }
  response.error = !r.ok;

  return response;
}

async function apiGet(url = '') {
  try {
    const r = await fetch(config.baseUrl + url, {
      method: 'GET',
      headers: GetHeaders(),
    });
    const res = await r.json();
    if (r.status == 401 || r.status == 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('is_staff');
      localStorage.removeItem('is_superuser');
      window.location.reload();
      throw new Error('Sin autorizacion');
    }
    response.error = !r.ok;
    response.res = res;
  } catch (err) {
    response.error = true;
  }

  return response;
}

async function apiDelete(url = '', data = {}) {
  const r = await fetch(config.baseUrl + url, {
    method: 'DELETE',
    headers: GetHeaders(),
    body: JSON.stringify(data),
  });
  try {
    const res = await r.json();
    response.res = res;
  } catch (err) {
    response.res = {};
  }
  response.error = !r.ok;

  return response;
}

async function apiLocation(street = '', number = 0) {
  try {
    const r = await fetch(
      `${config.locationUrl}BusquedaDireccion?calle=${encodeURI(
        street + ' ' + number.toString(),
      )}&departamento=montevideo&localidad=montevideo`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    try {
      const res = await r.json();
      var correctAddressFound = false;
      var correctAddress = {};
      if (res.length > 0) {
        res.map((address, i) => {
          if (
            address.error == '' &&
            address.direccion &&
            address.direccion.numero.nro_puerta == number &&
            !correctAddressFound
          ) {
            correctAddress = address;
            correctAddressFound = true;
          }
        });
      }
      response.res = correctAddress;
    } catch {
      response.res = {};
    }

    response.error = !r.ok;
  } catch {
    response.error = false;
    response.res = {};
  }

  return response;
}

async function apiStreetLocation(street = '') {
  const r = await fetch(
    `${config.locationUrl}SugerenciaCalleCompleta?entrada=${encodeURI(
      street,
    )}%2C%20montevideo%2C%20montevideo&todos=false`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const res = await r.json();
  response.error = !r.ok;
  response.res = res;

  return response;
}

const s3Upload = async (url, fields, img) => {
  let requestBody = new FormData();
  Object.keys(fields).forEach(key => {
    requestBody.append(key, fields[key]);
  });
  requestBody.append('file', img);
  const r = await fetch(url, {
    method: 'POST',
    body: requestBody,
  });
  console.log(r);
  return r.ok;
};

export { apiPost, apiPatch, apiGet, apiDelete, apiLocation, apiStreetLocation, s3Upload };

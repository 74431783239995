import React, { useEffect, useState } from 'react';
import {
  CardBody,
  Card,
  Row,
  Col,
  ButtonToggle,
  Button,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from 'reactstrap';
import Error from '../Utils/Error';
import style from './style.module.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { customSelectStyle } from '../Utils/CustomStyle';
import Success from '../Utils/Success';
import { apiPost, apiDelete, apiGet } from '../../services/services';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import RegisterArticle from './RegisterArticle';
import { ArticleType } from '../Utils/UtilsData';
import { GetValueArticleLabel } from '../Utils/UtilFunctions';

const animatedComponents = makeAnimated();

const AddDeleteArticle = ({ gardenId, toggle, article }) => {
  const [articlesSelected, setArticlesSelected] = useState();
  const [articleInfo, setArticleInfo] = useState({ showInput: false, title: '' });
  const [toggleRegisterArticle, setToggleRegisterArticle] = useState(false);
  const [registerResponse, setRegisterResponse] = useState({ error: false, message: '' });
  const [articleTypes, setArticlesTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    var articleValue = '';
    if (article.title !== '') {
      articleValue = article.title;
    } else {
      articleValue = ArticleType[0].label;
    }
    setArticleInfo({ ...articleInfo, showInput: article.title == '', title: articleValue });
    var articleVal = GetValueArticleLabel(articleValue);
    var articlesTypesByCategory = [];
    apiGet('articulo/tipo/' + articleVal.toString() + '/', {}).then(r => {
      r?.res?.forEach(item => {
        articlesTypesByCategory.push({
          value: item.id,
          label: item.nombre,
        });
      });
      var buttonAddArticleExists = false;
      articlesTypesByCategory.forEach(item => {
        if (item.value == 'agregarArticulo') {
          buttonAddArticleExists = true;
          return;
        }
      });
      if (!buttonAddArticleExists) {
        articlesTypesByCategory.push({ value: 'agregarArticulo', label: 'Seleccione para agregar un nuevo artículo' });
      }
      setArticlesTypes(articlesTypesByCategory);
    });
    if (article.items.length > 0) {
      setArticlesSelected(article.items);
    } else {
      apiGet('huerta/' + gardenId.toString() + '/articulos', {}).then(r => {
        var selected = [];
        if (r.res.length > 0) {
          r.res.map((item, i) => {
            if (item.tipo_articulo == articleVal) {
              selected.push({ value: item.id, label: item.nombre });
            }
          });
        }
        setArticlesSelected(selected);
      });
    }
  };

  const submitForm = e => {
    e.preventDefault();
    setLoading(true);
    var addArticles = [];
    var existArticle = false;
    articlesSelected.forEach(selected => {
      existArticle = false;
      article.items.forEach(item => {
        if (selected.value == item.value) {
          existArticle = true;
        }
      });
      if (!existArticle) {
        addArticles.push(selected.value);
      }
    });
    var deleteArticles = [];
    existArticle = false;
    article.items.forEach(item => {
      existArticle = false;
      articlesSelected.forEach(selected => {
        if (selected.value == item.value) {
          existArticle = true;
        }
      });
      if (!existArticle) {
        deleteArticles.push(item.value);
      }
    });
    if (addArticles.length > 0) {
      const bodyAdd = {
        huerta_id: gardenId,
        lista_articulo_id: addArticles,
      };
      apiPost('huerta/articulo/', bodyAdd).then(r => {
        if (r.error == false) {
          setRegisterResponse({ show: true, error: r.error, message: 'Artículos actualizados correctamente' });
        } else {
          let message = 'Error en:';
          Object.entries(r.res).forEach(([key, errors], index) => {
            message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
              .toString()
              .toLowerCase()})`;
          });
          setRegisterResponse({ error: r.error, message });
        }
      });
    }
    if (deleteArticles.length > 0) {
      const bodyDelete = {
        huerta_id: gardenId,
        lista_articulo_id: deleteArticles,
      };
      apiDelete('huerta/articulo/', bodyDelete).then(r => {
        if (r.error == false) {
          setRegisterResponse({ show: true, error: r.error, message: 'Artículos actualizados correctamente' });
        } else {
          let message = 'Error en:';
          Object.entries(r.res).forEach(([key, errors], index) => {
            message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
              .toString()
              .toLowerCase()})`;
          });
          setRegisterResponse({ error: r.error, message });
        }
      });
    }
    setLoading(false);
  };

  const onChange = e => {
    e.forEach(item => {
      if (item.value == 'agregarArticulo') {
        setToggleRegisterArticle(true);
        e.pop();
        return;
      }
    });
    setArticlesSelected(e);
  };

  const onChangeArticle = (name, value) => {
    var articleVal = GetValueArticleLabel(value);
    var articlesTypesByCategory = [];
    apiGet('articulo/tipo/' + articleVal.toString() + '/', {}).then(r => {
      r.res.forEach(item => {
        articlesTypesByCategory.push({
          value: item.id,
          label: item.nombre,
        });
      });
      var buttonAddArticleExists = false;
      articlesTypesByCategory.forEach(item => {
        if (item.value == 'agregarArticulo') {
          buttonAddArticleExists = true;
          return;
        }
      });
      if (!buttonAddArticleExists) {
        articlesTypesByCategory.push({ value: 'agregarArticulo', label: 'Seleccione para agregar un nuevo artículo' });
      }
      setArticlesTypes(articlesTypesByCategory);
      setArticleInfo({ ...articleInfo, [name]: value });
      setArticlesSelected([]);
    });
    apiGet('huerta/' + gardenId.toString() + '/articulos', {}).then(r => {
      var selected = [];
      if (r.res.length > 0) {
        r.res.map((item, i) => {
          if (item.tipo_articulo == articleVal) {
            selected.push({ value: item.id, label: item.nombre });
          }
        });
      }
      setArticlesSelected(selected);
    });
  };

  const registerArticleSubmit = toggle => {
    setToggleRegisterArticle(toggle);
    update();
  };

  return (
    <div className={style.overlay}>
      <div className={style.body}>
        <div className={style.center}>
          {toggleRegisterArticle && (
            <RegisterArticle
              isUser={true}
              toggle={registerArticleSubmit}
              articleType={articleInfo.title}
              gardenId={gardenId}
            />
          )}
          <Card className={style.card}>
            <CardBody>
              <div className={style.right} onClick={() => toggle(false)}>
                <i className="fas fa-times"></i>
              </div>

              {registerResponse.show && (
                <Row>
                  <Col>
                    {registerResponse.error ? (
                      <Error message={registerResponse.message} />
                    ) : (
                      <Success message={registerResponse.message} />
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col className={style.titleArticle}> Agregar o eliminar artículo</Col>
              </Row>

              <Form className={style.Form} onSubmit={e => submitForm(e)}>
                {articleInfo.showInput ? (
                  <FormGroup className={style.FormGroup}>
                    <Label for="title" className={style.labelArticle}>
                      Tipo de artículo
                    </Label>

                    <Input
                      type="select"
                      name="title"
                      id="title"
                      placeholder="Tipo de articulo"
                      value={articleInfo.title}
                      onChange={e => onChangeArticle(e.target.name, e.target.value)}
                    >
                      {ArticleType.map((article, i) => {
                        return <option key={i}>{article.label}</option>;
                      })}
                    </Input>
                  </FormGroup>
                ) : (
                  <div className={style.styleItem}>
                    {'  '}
                    {articleInfo.title}
                  </div>
                )}
                <Select
                  closeMenuOnSelect={false}
                  width="100%"
                  menuColor="red"
                  styles={customSelectStyle}
                  components={animatedComponents}
                  isMulti
                  value={articlesSelected}
                  options={articleTypes}
                  placeholder='Seleccione los artículos'
                  onChange={e => onChange(e)}
                  noOptionsMessage={() => 'Sin resultados'}
                />

                <Container>
                  <Row xs="1" sm="1" md="2" lg="2">
                    <Col>
                      <Button className={style.ButtonSecondary} size="lg" onClick={() => toggle(false)}>
                        Cerrar
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className={style.ButtonPrimary} size="lg">
                        Confirmar
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AddDeleteArticle;

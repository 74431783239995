import React, { useEffect, useState } from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import style from './style.module.scss';
import { apiPost, s3Upload } from '../../services/services';

const AddImage = ({ gardenId, toggle, reload }) => {
  const [image, setImage] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const addImage = () => {
    if (!image) {
      console.log('Imagen no seleccionada');
      setErrorMsg('Por favor seleccione una imagen');
      return;
    }
    apiPost('huerta/' + gardenId + '/imagenes', {}).then(response => {
      if (!response.error) {
        console.log(response.res.url);
        console.log(response.res.fields);
        if (s3Upload(response.res.url, response.res.fields, image)) {
          reload();
          toggle();
        } else {
          console.log('Ocurrio un error subiendo la imagen, intente nuevamente');
          alert('Ocurrio un error subiendo la imagen, intente nuevamente');
        }
        // le pego a aws
      } else {
        // TODO: Error al subir foto
        console.log(response.res.error);
        setErrorMsg(response.res.error);
      }
    });
  };
  return (
    <Modal returnFocusAfterClose={false} isOpen={true} toggle={toggle} size="lg">
      <ModalBody>
        <div className={style.imageModalContent}>
          <div className={style.title}>Agregar Foto</div>
          <div className={style.imageInput}>
            <Form>
              <FormGroup>
                <Label for="fileInput">Imagen</Label>
                <Input
                  id="fileInput"
                  type="file"
                  style={{ width: '100%' }}
                  accept="image/*"
                  onChange={e => setImage(e.target.files[0])}
                  invalid={errorMsg}
                ></Input>
                <FormFeedback>{errorMsg}</FormFeedback>
              </FormGroup>
            </Form>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <Button className={style.ButtonSecondary} onClick={toggle}>
              Cancelar
            </Button>
            <Button className={style.ButtonPrimary} onClick={addImage} disabled={!image}>
              Agregar Foto
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddImage;

export const GardenType = [
  { value: 'EN_CASA', label: 'En casa' },
  { value: 'INSTITUCIONAL', label: 'Institucional' },
  { value: 'COMUNITARIA', label: 'Comunitaria' },
];

//TODO incluir archivada para el admin
export const GardenStatus = [
  { value: 'INACTIVA', label: 'Inactiva' },
  { value: 'ACTIVA', label: 'Activa' },
];

export const GardenPrivacy = [
  { value: 'public', label: 'Pública' },
  { value: 'private', label: 'Privada' },
];

export const SemillaPrueba = [
  { id: 7, value: 'semilla1' },
  { id: 8, value: 'semilla2' },
  { id: 10, value: 'semilla3' },
  { id: 12, value: 'semilla4' },
];

export const Districts = [
  { value: 'PASO_DE_LA_ARENA', label: 'Paso de la Arena' },
  { value: 'NUEVO_PARIS', label: 'Nuevo París' },
  { value: 'BELVEDERE', label: 'Belvedere' },
  { value: 'PRADO_NUEVA_SAVONA', label: 'Prado Nueva Savona' },
  { value: 'LA_TEJA', label: 'La Teja' },
  { value: 'CERRO', label: 'Cerro' },
  { value: 'CASABO', label: 'Casabó' },
  { value: 'PAJAS_BLANCAS', label: 'Pajas Blancas' },
  { value: 'LA_PALOMA_TOMKINSON', label: 'La Paloma Tomkinson' },
  { value: 'TRES_OMBUES_PUEBLO_VICTORIA', label: 'Tres Ombúes Pueblo Victoria' },
];

export const ArticleType = [
  { value: 'COMESTIBLE', label: 'Comestible' },
  { value: 'HERRAMIENTA', label: 'Herramienta' },
  { value: 'SEMILLA', label: 'Semilla' },
  { value: 'PLANTIN', label: 'Plantín' },
  { value: 'CAPACITACION', label: 'Capacitación' },
  { value: 'OTRO', label: 'Otro' },
];

export const MonthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const SearchCategoryType = [
  { value: 'HUERTA', label: 'Huerta' },
  { value: 'RED', label: 'Red' },
  { value: 'HERRAMIENTA', label: 'Herramienta' },
  { value: 'COMESTIBLE', label: 'Comestible' },
  { value: 'SEMILLA', label: 'Semilla' },
  { value: 'PLANTIN', label: 'Plantín' },
  { value: 'CAPACITACION', label: 'Capacitación' },
];

export const UserType = { USER: 'Usuario', ADMIN: 'Administrador', STAFF: 'Staff' };

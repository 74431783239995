import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  Spinner,
  Tooltip,
  FormFeedback,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { apiLocation, apiPost, apiStreetLocation, apiGet } from '../../services/services';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import style from './style.module.scss';
import Select from 'react-select';
import { Districts, GardenPrivacy, GardenType } from '../Utils/UtilsData';
import makeAnimated from 'react-select/animated';
import { GetValueDistrictLabel, IsAdministratorUserLogged, IsStaffUserLogged } from '../Utils/UtilFunctions';
import { customSelectStyle } from '../Utils/CustomStyle';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import MapSelectPosition from '../Maps/MapsSelectPosition';

const animatedComponents = makeAnimated();

const RegisterGarden = () => {
  const [registerGarden, setRegisterGarden] = useState({
    name: '',
    institution: '',
    description: '',
    type: GardenType[0].value,
    privacy: GardenPrivacy[0].value,
    district: Districts[0].label,
    streetId: 0,
    street: '',
    address: '',
    addressNumber: '',
    isPublic: false,
    publicLocation: false,
    publicContact: false,
    acceptNewMembers: false,
    confirmLoading: false,
    disableRecess: false,
    nets: [],
  });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [redirect, setRedirect] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({ publicContact: false, acceptNewMembers: false, privacy: false });
  const [optionStreets, setOptionStreets] = useState([]);
  const [dropdownOpen, setOpen] = useState(false);
  const [positionMap, setPositionMap] = useState(null);
  const [mapModal, setMapModal] = useState({ show: false, message: '' });
  const [loadingStreet, setLoadingStreet] = useState(false);
  const [nets, setNets] = useState([]);
  const [allNets, setAllNets] = useState([]);
  const [descriptionCount, setDescriptionCount] = useState(0);

  useEffect(() => {
    apiGet('huerta/red/', {}).then(r => {
      if (r.error == false) {
        setAllNets([...r.res]);
      }
    });
  }, []);

  const toggle = (name, value) => {
    setTooltipOpen({ ...tooltipOpen, [name]: value });
  };

  const submitForm = e => {
    e.preventDefault();
    var isValid = registerGarden.name !== '' && registerGarden.street !== '' && registerGarden.streetId !== '';
    if (!isValid) {
      setRegisterResponse({ show: true, error: true, message: 'No se completaron todos los campos obligatorios' });
    } else {
      setRegisterGarden({ ...registerGarden, confirmLoading: true, lat: 0, long: 0 });
      apiLocation(registerGarden.street, registerGarden.addressNumber).then(r => {
        if (!r.error) {
          if (r.res.puntoX && r.res.puntoY) {
            createGarden(r.res.puntoY, r.res.puntoX);
          } else {
            setMapModal({
              ...mapModal,
              show: true,
              message: 'Dirección no encontrada',
            });
            setRegisterGarden({ ...registerGarden, confirmLoading: false });
          }
        } else {
          setMapModal({
            ...mapModal,
            show: true,
            message: 'Ha ocurrido un error con la dirección seleccionada',
          });
          setRegisterGarden({ ...registerGarden, confirmLoading: false });
        }
      });
    }
    window.scrollTo(0, 0);
  };

  const createGarden = (lat, long) => {
    var body = {
      nombre: registerGarden.name,
      descripcion: registerGarden.description,
      tipo: registerGarden.type,
      ubicacion: {
        latitud: lat.toFixed(6),
        longitud: long.toFixed(6),
        direccion: registerGarden.street + ' ' + registerGarden.addressNumber,
        barrio: GetValueDistrictLabel(registerGarden.district),
      },
      es_publica: registerGarden.privacy == GardenPrivacy[0].value,
      contacto_es_publico: registerGarden.publicContact,
      acepta_nuevos_miembros: registerGarden.acceptNewMembers,
      redes: nets.map(item => item.value),
      estado: isStaff ? 'ACTIVA' : 'INACTIVA',
    };
    if (registerGarden.type === 'INSTITUCIONAL') {
      body = {
        ...body,
        nombre_institucion: registerGarden.institution,
        inactiva_en_vacaciones: registerGarden.disableRecess,
      };
    }
    apiPost('huerta/', body).then(r => {
      setRegisterGarden({ ...registerGarden, confirmLoading: false });
      if (r.error == false) {
        setRegisterResponse({ show: true, error: r.error, message: 'Huerta creada correctamente' });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else {
        setRegisterResponse({ show: true, error: r.error, message: JSON.stringify(r.res.error) });
      }
      window.scrollTo(0, 0);
    });
  };

  const onChange = (name, value) => {
    setOpen(false);
    setRegisterGarden({ ...registerGarden, [name]: value });
  };

  const onChangeStreet = (name, value) => {
    setLoadingStreet(true);
    setRegisterGarden({ ...registerGarden, [name]: value });
    var streets = [];
    apiStreetLocation(value).then(r => {
      if (!r.error) {
        r.res.map((street, i) => {
          streets.push({ id: street.idCalle, value: street.calle });
        });
        setOpen(true);
      }
      setLoadingStreet(false);
      setOptionStreets(streets);
    });
  };

  const onConfirmPosition = () => {
    setMapModal({ show: false, message: '' });
    createGarden(positionMap?.lat, positionMap?.lng);
  };

  const onCancelMapModal = () => {
    setMapModal({ ...mapModal, show: false, message: '' });
    setRegisterResponse({
      show: true,
      error: true,
      message: 'Es obligatorio que seleccione una ubicación en el mapa para crear la huerta',
    });
  };

  const isStaff = IsAdministratorUserLogged() || IsStaffUserLogged();

  const recalculate = e => {
    setDescriptionCount(e.target.value.length);
  };

  if (redirect) {
    return <Redirect to="/home" />;
  }
  return (
    <div className={style.body}>
      <div className={style.center}>
        <Card className={style.card}>
          <CardBody>
            {mapModal.show && (
              <MapSelectPosition
                message={mapModal.message}
                position={positionMap}
                setPosition={setPositionMap}
                confirmPosition={onConfirmPosition}
                cancelPosition={onCancelMapModal}
              />
            )}
            {registerResponse.show && (
              <Row>
                <Col>
                  {registerResponse.error ? (
                    <Error message={registerResponse.message} />
                  ) : (
                    <Success message={registerResponse.message} />
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col className={style.title}>Crear una huerta</Col>
            </Row>
            <Row className={style.center}>
              <Col md="10">
                <Form
                  className={style.Form}
                  onSubmit={e => submitForm(e)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                >
                  <FormGroup className={style.FormGroup}>
                    <div className={style.labelContainer}>
                      <Label for="name">Nombre </Label>
                      <Label className={style.required}>(obligatorio)</Label>
                    </div>
                    <Input
                      type="name"
                      name="name"
                      id="name"
                      autocomplete="off"
                      placeholder="Nombre de la huerta"
                      value={registerGarden.name}
                      invalid={registerGarden.name === ''}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                    <FormFeedback className={style.invalidFeedback}>Se debe ingresar un nombre válido</FormFeedback>
                  </FormGroup>
                  <FormGroup tag="fieldset" className={style.FormGroup}>
                    <Row>
                      <Col sm="12" md="3">
                        Tipo de huerta
                      </Col>
                      {GardenType.map((type, i) => {
                        return (
                          <Col className={style.radio} key={i} xs="12" sm="4" md="3">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="type"
                                  id={type.value}
                                  checked={type.value === registerGarden.type}
                                  onChange={e => onChange(e.target.name, type.value)}
                                  className={style.checkbox}
                                />{' '}
                                {type.label}
                              </Label>
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  </FormGroup>
                  {registerGarden.type === 'INSTITUCIONAL' && (
                    <React.Fragment>
                      <FormGroup className={style.FormGroup}>
                        <Label for="institution">Institución</Label>
                        <Input
                          type="text"
                          name="institution"
                          id="institution"
                          placeholder="Institución"
                          value={registerGarden.institution}
                          onChange={e => onChange(e.target.name, e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup check className={style.FormGroup}>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="disableRecess"
                            id="disableRecess"
                            value={registerGarden.disableRecess}
                            onChange={e => onChange(e.target.name, e.target.checked)}
                            className={style.inputCheckbox}
                          />{' '}
                          <div className={style.inputCheckbox}>Inactividad por vacaciones</div>
                        </Label>
                      </FormGroup>
                    </React.Fragment>
                  )}
                  <FormGroup className={style.FormGroup}>
                    <Label for="institution" className={style.description}>
                      <span>Descripción</span>
                      <span style={{fontWeight: 'normal'}}>{descriptionCount}/1023</span>
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder="Ejemplo: Huerta orgánica que realiza talleres y comparte conocimiento…"
                      value={registerGarden.description}
                      onChange={e => {
                        onChange(e.target.name, e.target.value);
                        recalculate(e);
                      }}
                    />
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <FormGroup className={style.FormGroup}>
                        <div className={style.labelContainer}>
                          <Label for="street">Calle</Label>
                          <Label className={style.required}>(obligatorio)</Label>
                          <div className={style.spinnerSearch}>{loadingStreet ? <Spinner size="sm" /> : null}</div>
                        </div>
                        <UncontrolledDropdown isOpen={dropdownOpen} className={style.search} caret>
                          <DropdownToggle color="transparent" className={style.search}>
                            <Input
                              type="text"
                              name="street"
                              id="street"
                              placeholder="Calle"
                              value={registerGarden.street}
                              invalid={registerGarden.street === ''}
                              onChange={e => onChangeStreet(e.target.name, e.target.value)}
                            />{' '}
                            <FormFeedback className={style.invalidFeedback}>
                              Se debe ingresar una calle válida
                            </FormFeedback>
                          </DropdownToggle>
                          <DropdownMenu className={style.dropdownMenu}>
                            {optionStreets.map((street, i) => {
                              return (
                                <DropdownItem
                                  onClick={() => {
                                    setRegisterGarden({ ...registerGarden, street: street.value, streetId: street.id });
                                    setOpen(false);
                                  }}
                                  key={i}
                                >
                                  {street.value}
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className={style.FormGroup}>
                        <div className={style.labelContainer}>
                          <Label for="addressNumber">Número</Label>
                          <Label className={style.required}>(obligatorio)</Label>
                        </div>
                        <Col>
                          <Input
                            type="number"
                            name="addressNumber"
                            id="addressNumber"
                            placeholder="Número de puerta"
                            value={registerGarden.addressNumber}
                            invalid={registerGarden.addressNumber === ''}
                            onChange={e => onChange(e.target.name, e.target.value)}
                          />
                          <FormFeedback className={style.invalidFeedback}>
                            Se debe ingresar un número válido
                          </FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup className={style.FormGroup}>
                        <Label for="institution">Barrio</Label>
                        <Col>
                          <Input
                            type="select"
                            name="district"
                            id="district"
                            placeholder="Barrio"
                            value={registerGarden.district}
                            onChange={e => onChange(e.target.name, e.target.value)}
                          >
                            {Districts.map((district, i) => {
                              return <option key={i}>{district.label}</option>;
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="11">
                      <FormGroup check className={style.FormGroup}>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="publicContact"
                            id="publicContact"
                            value={registerGarden.publicContact}
                            onChange={e => onChange(e.target.name, e.target.checked)}
                            className={(style.inputCheckbox, style.checkbox)}
                          />{' '}
                          <div className={style.inputCheckbox}>
                            Quiero que la forma de contacto de mi huerta sea pública para todos los usuarios.
                          </div>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="1" id="DisabledAutoHideContact" className={style.tooltipQuestion}>
                      <i className="far fa-question-circle"></i>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen.publicContact}
                        autohide={false}
                        target="DisabledAutoHideContact"
                        toggle={e => toggle('publicContact', !tooltipOpen.publicContact)}
                      >
                        Si selecciona esta opción el resto de los usuarios no podrá ver su télefono ni su email.
                      </Tooltip>
                    </Col>
                  </Row>
                  {/* <FormGroup check className={style.FormGroup}>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="publicLocation"
                        id="publicLocation"
                        value={registerGarden.publicLocation}
                        onChange={e => onChange(e.target.name, e.target.value)}
                      />{' '}
                      Quiero que la ubicación de mi huerta sea pública para todos los usuarios.
                    </Label>
                  </FormGroup> */}
                  <Row>
                    <Col md="11">
                      <FormGroup check className={style.FormGroup}>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="acceptNewMembers"
                            id="acceptNewMembers"
                            value={registerGarden.acceptNewMembers}
                            onChange={e => onChange(e.target.name, e.target.checked)}
                            className={style.checkbox}
                          />{' '}
                          <div className={style.inputCheckbox}>Se aceptan usuarios colaboradores.</div>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="1" id="DisabledAutoHideCollaborators" className={style.tooltipQuestion}>
                      <i className="far fa-question-circle"></i>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen.acceptNewMembers}
                        autohide={false}
                        target="DisabledAutoHideCollaborators"
                        toggle={e => toggle('acceptNewMembers', !tooltipOpen.acceptNewMembers)}
                      >
                        Si selecciona esta opción su huerta podrá contener usuarios colaboradores.
                      </Tooltip>
                    </Col>
                  </Row>
                  <FormGroup tag="fieldset" className={style.FormGroup}>
                    <Row>
                      <Col sm="12" md="4">
                        Privacidad de la huerta
                      </Col>
                      {GardenPrivacy.map((privacy, i) => {
                        return (
                          <Col className={style.radio} key={i} xs="12" sm="6" md="3">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="privacy"
                                  id={privacy.value}
                                  checked={privacy.value === registerGarden.privacy}
                                  onChange={e => onChange(e.target.name, privacy.value)}
                                  className={style.checkbox}
                                />{' '}
                                {privacy.label}
                              </Label>
                            </FormGroup>
                          </Col>
                        );
                      })}
                      <Col md="2" id="DisabledAutoHidePrivacy" className={style.tooltipQuestion}>
                        <i className="far fa-question-circle"></i>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen.privacy}
                          autohide={false}
                          target="DisabledAutoHidePrivacy"
                          toggle={e => toggle('privacy', !tooltipOpen.privacy)}
                        >
                          Si selecciona la opción privada los usuarios no podrán ver esta huerta una vez que esté
                          creada.
                        </Tooltip>
                      </Col>
                    </Row>
                  </FormGroup>
                  <Select
                    placeholder="Seleccione las redes para vincular a la huerta"
                    closeMenuOnSelect={false}
                    styles={customSelectStyle}
                    components={animatedComponents}
                    isMulti
                    options={allNets.map(item => ({ value: item.id, label: item.nombre }))}
                    value={nets}
                    onChange={setNets}
                  />
                  <Row className={style.Button}>
                    <Col md="6" xs="6">
                      <Link className={style.link} to="/home">
                        <Button className={style.ButtonSecondary} size="lg">
                          Cancelar
                        </Button>
                      </Link>
                    </Col>
                    <Col md="6" xs="6">
                      <Button type="submit" className={style.ButtonPrimary} size="lg">
                        Confirmar {registerGarden.confirmLoading ? <Spinner color="success" size="sm" /> : null}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default RegisterGarden;

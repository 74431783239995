import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import style from './style.module.scss';

const AdminArticleItem = ({ item, id, deleteArticle }) => {
  return (
    <div className={id > 0 ? style.borderTop : undefined}>
      <Container className={style.cardItem} fluid>
        <Row>
          <Col className={style.left}>{item.name}</Col>
          <Col md="1" sm="2" xs="3">
            <div onClick={() => deleteArticle(item.id)}>
              <div className={style.delete}>
                <i className="fas fa-trash-alt" />
              </div>
              {'  '}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminArticleItem;

import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner } from 'reactstrap';
import MapView from '../Maps/Maps';
import style from './style.module.scss';
import { Link } from 'react-router-dom';
import { apiGet } from '../../services/services';
import GardenInfoItem from './GardenInfoItem';
import { useHistory } from 'react-router-dom';
import { IsAdministratorUserLogged } from '../Utils/UtilFunctions';

const Garden = () => {
  const [loading, setLoading] = useState(true);
  const [garden, setGarden] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    apiGet('huerta/', {}).then(r => {
      setLoading(false);
      var gardenMap = [];
      if (r.error == false) {
        r.res.map(item => {
          if ((!IsAdministratorUserLogged() && item.estado !== 'ARCHIVADA') || IsAdministratorUserLogged()) {
            gardenMap.push({
              id: item.id,
              name: item.nombre,
              nets: item.redes_names,
              tel: item.referente?.telefono,
              location: item.ubicacion?.direccion,
              email: item.referente?.email,
              updateDate: new Date(item.updated_at),
              lat: item.ubicacion?.latitud,
              long: item.ubicacion?.longitud,
            });
          }
        });
        const gardenMapSorted = gardenMap?.sort((a, b) => (a.name > b.name ? 1 : -1));
        setGarden(gardenMapSorted);
      }
    });
  }, []);

  useEffect(() => {
    var user = localStorage.getItem('currentUser');
    setUser(user);
  }, [localStorage.getItem('currentUser')]);

  return (
    <div className={style.body}>
      <div className={style.background}>
        <Row className={style.title}>
          <Col className={style.left}>Huertas</Col>
          <Col className={style.right}>
            {user ? (
              <Link className={style.linkBtnPrimary} to="/registergarden">
                {' '}
                <Button className={style.ButtonPrimary}>
                  <i className="fas fa-plus" />
                  {'  '}Agregar Huerta
                </Button>
              </Link>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row className={`${style.bodyGarden} ${loading ? style.loading : ''}`}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {garden.length > 0 && (
                <Col md="6" className={style.gardenMap}>
                  <MapView gardens={garden} className={style.gardenInfoItem} />
                </Col>
              )}
              {garden.length > 0 ? (
                <Col md="6" className={style.gardenGroup}>
                  {garden.map((item, i) => {
                    return (
                      <Row className={style.gardenInfoItem} key={i}>
                        <GardenInfoItem item={item} />
                      </Row>
                    );
                  })}
                </Col>
              ) : (
                <div className={style.imgContainer}>
                  No se han encontrado huertas públicas
                  <img src="Assets/emptyGardenList.jpg" className={style.emptyListImg} />
                </div>
              )}
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Garden;

import React, { useState, useEffect } from 'react';
import { Row, Col, CardTitle, Card, CardBody, Container } from 'reactstrap';
import { apiGet, apiDelete } from '../../services/services';
import style from './style.module.scss';
import RegisterEvent from '../Events/RegisterEvent';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const CreatedEvents = () => {
  const [userEvents, setUserEvents] = useState([]);
  const [editEvent, setEditEvent] = useState({ show: false, item: {} });
  const [confirmationModalDelete, setConfirmationModalDelete] = useState({
    show: false,
    reference: '',
    eventId: '',
  });
  const [deleteResponse, setDeleteResponse] = useState({
    show: false,
    error: false,
    message: '',
  });

  useEffect(() => {
    apiGet('usuario/miseventos', {}).then(r => {
      if (r.error == false) {
        setUserEvents(r.res);
      }
    });
  }, []);

  const updateEvent = eventItem => {
    setEditEvent({ show: true, item: eventItem });
  };

  const confirmDeleteEvent = id => {
    apiDelete('evento/' + id + '/').then(r => {
      if (r.error) {
        setDeleteResponse({
          show: true,
          error: false,
          message: 'No se pudo eliminar el evento',
        });
      } else {
        setDeleteResponse({
          show: true,
          error: false,
          message: 'Evento eliminado correctamente',
        });
        setTimeout(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        }, 1000);
      }
    });
  };

  if (userEvents.length === 0) {
    return <div className={style.emptyList}> No ha creado ningún evento </div>;
  } else {
    return (
      <Row>
        {deleteResponse.show && (
          <Row>
            <Col>
              {deleteResponse.error ? (
                <Error message={deleteResponse.message} />
              ) : (
                <Success message={deleteResponse.message} />
              )}
            </Col>
          </Row>
        )}
        <ConfirmationModal
          isOpen={confirmationModalDelete.show}
          title="Eliminar evento"
          message="eliminar el evento"
          onConfirm={() => confirmDeleteEvent(confirmationModalDelete.eventId)}
          onClose={() => setConfirmationModalDelete({ show: false, reference: '' })}
        />
        {editEvent.show && (
          <div className={style.overlay}>
            <div className={style.body}>
              <div className={style.center}>
                <RegisterEvent update={true} item={editEvent.item} closePopUp={setEditEvent} />
              </div>
            </div>
          </div>
        )}
        <Container fluid>
          <Row lg={4} md={2} sm={1} xs={1}>
            {userEvents.map((eventItem, i) => {
              const date = eventItem.fecha_hora.split(' ')[0].replaceAll('-', '/');
              const time = eventItem.fecha_hora.split(' ')[1].substring(0, 5);
              return (
                <Col key={i} className={style.cardColItem}>
                  <Card body className={style.cardGardenItem}>
                    <CardTitle className={style.cardTitle}>
                      <Row>
                        <Col className={style.gardenName}>
                          <div className={style.iconContainer}>
                            <i className="far fa-calendar-alt" />
                            {eventItem.nombre}
                          </div>
                        </Col>
                        <Col className={style.dateIcons}>
                          <Col className={style.date}>
                            {date} {time}
                          </Col>
                          <Col className={style.iconEdit} onClick={() => updateEvent(eventItem)}>
                            <i className="fas fa-pen" />
                          </Col>
                          <Col
                            className={style.iconDelete}
                            onClick={() =>
                              setConfirmationModalDelete({
                                show: true,
                                reference: eventItem.id,
                                eventId: eventItem.id,
                              })
                            }
                          >
                            <i className="fas fa-trash" />
                          </Col>
                        </Col>
                      </Row>
                    </CardTitle>
                    <CardBody>
                      <Row>
                        <Col className={style.address}>
                          {eventItem.ubicacion && (
                            <div className={style.iconContainer}>
                              <i className="fas fa-map-marker-alt" />
                              {eventItem.ubicacion.direccion}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <div className={style.description}>{eventItem.descripcion}</div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Row>
    );
  }
};

export default CreatedEvents;

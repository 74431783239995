import React from "react";
import { CardBody, Card, Row, Col, Button } from "reactstrap";
import style from "./style.module.scss";

const Footer = () => {
  return (
    <div className={style.container}>
      <Col className={style.left}>
        <img
          src="Assets/fing_logo.png"
          className={style.logo}
          onClick={() => window.open("https://www.fing.edu.uy/", "_blank")}
        />
      </Col>
      <Col className={style.right}>
        <img
          src="Assets/apex_logo.png"
          className={style.logo}
          onClick={() => window.open("http://apex.edu.uy/wordpress/", "_blank")}
        />
        <img
          src="Assets/udelar_logo.png"
          className={style.logo}
          onClick={() => window.open("https://udelar.edu.uy/", "_blank")}
        />
      </Col>
    </div>
  );
};

export default Footer;

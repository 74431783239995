import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CardBody, Card, Row, Col, FormGroup, Form, Label, Input, Button, FormFeedback } from 'reactstrap';
import { apiPost } from '../../services/services';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import style from './style.module.scss';

const RegisterUser = () => {
  const [registerUser, setRegisterUser] = useState({ email: '', name: '', password: '', tel: '' });
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [redirect, setRedirectHome] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    const requiredFieldsCompleted = registerUser.email !== '' && registerUser.password !== '';
    if (!requiredFieldsCompleted) {
      setRegisterResponse({ show: true, error: true, message: 'No se completaron todos los campos obligatorios' });
    } else {
      if (isNaN(Number(registerUser.tel))) {
        setRegisterResponse({ show: true, error: true, message: 'El telefono ingresado debe ser un numero' });
      } else {
        var body = {
          email: registerUser.email,
          first_name: registerUser.name,
          password: registerUser.password,
          telefono: registerUser.tel,
        };
        apiPost('usuario/', body).then(r => {
          if (r.error == false) {
            setRegisterResponse({ show: true, error: r.error, message: 'Usuario creado correctamente' });
            setTimeout(() => {
              localStorage.setItem('token', r.res.token);
              localStorage.setItem('currentUser', JSON.stringify(registerUser.email));
              setRedirectHome(true);
            }, 3000);
          } else {
            let message = '';
            Object.entries(r.res).forEach(([key, errors], index) => {
              message = `${errors
                .toString()}`;
            });
            setRegisterResponse({ show: true, error: r.error, message });
          }
        });
      }
    }
  };

  const onChange = (name, value) => {
    setRegisterUser({ ...registerUser, [name]: value });
  };

  if (redirect) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={style.body}>
      <div className={style.center}>
        <Card className={style.card}>
          <CardBody>
            {registerResponse.show && (
              <Row>
                <Col>
                  {registerResponse.error ? (
                    <Error message={registerResponse.message} />
                  ) : (
                    <Success message={registerResponse.message} />
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col className={style.arrow}>
                <Link className={style.link} to="/home">
                  <i className="fas fa-arrow-left fa-lg"></i>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className={style.title}>Crear un usuario</Col>
            </Row>

            <Row className={style.center}>
              <Col md="10">
                <Form className={style.Form} onSubmit={e => submitForm(e)}>
                  <FormGroup className={style.FormGroup}>
                    <div className={style.labelContainer}>
                      <Label for="email">Correo electrónico </Label>
                      <Label className={style.required}>(obligatorio)</Label>
                    </div>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Correo electrónico"
                      value={registerUser.email}
                      invalid={registerUser.email === ''}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                    <FormFeedback className={style.invalidFeedback}>
                      Se debe ingresar un correo electrónico
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup className={style.FormGroup}>
                    <Label for="first_name">Nombre de usuario</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Nombre del usuario"
                      value={registerUser.name}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className={style.FormGroup}>
                    <div className={style.labelContainer}>
                      <Label for="password">Contraseña</Label>
                      <Label className={style.required}>(obligatorio)</Label>
                    </div>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Contraseña"
                      value={registerUser.password}
                      invalid={registerUser.password === ''}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                    <FormFeedback className={style.invalidFeedback}>Se debe ingresar una contraseña</FormFeedback>
                  </FormGroup>
                  <FormGroup className={style.FormGroup}>
                    <Label for="tel">Teléfono</Label>
                    <Input
                      type="text"
                      name="tel"
                      id="tel"
                      placeholder="Teléfono"
                      value={registerUser.tel}
                      invalid={isNaN(Number(registerUser.tel))}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <Row className={style.Button}>
                    <Col md="6" xs="6">
                      <Link className={style.link} to="/home">
                        <Button className={style.ButtonSecondary} size="lg">
                          Cancelar
                        </Button>
                      </Link>
                    </Col>
                    <Col md="6" xs="6">
                      <Button type="submit" className={style.ButtonPrimary} size="lg">
                        Confirmar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default RegisterUser;

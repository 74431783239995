import React from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import style from './style.module.scss';
import { useHistory } from 'react-router-dom';
import { IsSameUserLogged } from '../Utils/UtilFunctions';

const AdminUserListItem = ({ item, id, moderatorUpdate, deleteUser }) => {
  const history = useHistory();
  return (
    <div className={id > 0 ? style.borderTop : undefined} onClick={() => history.push('/admin/user/' + item.id)}>
      <Container className={style.cardItem}>
        <Row xs="1" sm="1" md="3" lg="4">
          <Col className={style.left}>
            <div className={style.infoContainer}>
              <div className={style.iconContainer}>
                <i className="fas fa-envelope"></i>
              </div>
              &nbsp;
              {item.email}
            </div>
          </Col>
          <Col>{item.name !== '' && <div className={style.left}>{item.name}</div>}</Col>
          <Col className={style.left}>
            {item.tel !== '' && (
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-phone"></i>
                </div>
                &nbsp;
                {item.tel}
              </div>
            )}
          </Col>

          <Col className={style.alignLeft} xs="12" sm="12" md="12">
            {!IsSameUserLogged(item.email) && (
              <div className={style.buttonDisplay}>
                <div className={style.buttonWidth}>
                  <Button
                    className={item.isModerator ? style.moderatorFalseButton : style.moderatorTrueButton}
                    sm={{ size: 'auto' }}
                    onClick={e => {
                      e.stopPropagation();
                      moderatorUpdate(item.id);
                    }}
                  >
                    {item.isModerator ? 'Quitar rol moderador' : 'Aplicar rol moderador'}
                  </Button>
                </div>
                <div className={style.buttonWidth}>
                  <Button
                    className={style.deleteButton}
                    sm={{ size: 'auto' }}
                    onClick={e => {
                      e.stopPropagation();
                      deleteUser(item.id);
                    }}
                  >
                    Eliminar
                  </Button>

                  {'  '}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminUserListItem;

import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import style from './style.module.scss';
import { useHistory } from 'react-router-dom';

const AdminGardenListItem = ({ item, id, openUsersModal }) => {
  const history = useHistory();
  return (
    <div className={id > 0 ? style.borderTop : undefined} onClick={() => history.push('/garden/' + item.id)}>
      <Container className={style.gardenItem} fluid>
        <Row xs="1" sm="1" md="3" lg="5">
          <Col className={style.alignLeft}>
            <div className={style.infoContainer}>
              <div className={style.selectItem}>{item.name}</div>
            </div>
          </Col>
          <Col className={style.alignLeft}>
            <div className={style.infoContainer}>
              <div className={style.iconContainer}>
                <i className="fas fa-envelope"></i>
              </div>
              &nbsp;
              <div className={style.selectItem}>{item.email}</div>
            </div>
          </Col>
          <Col className={style.alignLeft}>
            {item.tel && (
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-phone"></i>
                </div>
                &nbsp;
                {item.tel}
              </div>
            )}
          </Col>
          <Col className={style.alignLeft}>
            {item.location && (
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-map-marker-alt" />
                </div>
                &nbsp;
                {item.location}
              </div>
            )}
          </Col>
          {item.type != 'En casa' && localStorage.getItem('is_superuser') === 'true' && (
            <Col className={style.buttonRight}>
              <Button
                className={style.ButtonPrimary}
                size="sm"
                onClick={e => {
                  e.stopPropagation();
                  openUsersModal(item.id);
                }}
              >
                Cambiar referente
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default AdminGardenListItem;

import React, { useState } from 'react';
import SearchBar from '../SearchBar/SearchBar';
import List from '../List/List';
import style from './style.module.scss';
import { apiPost } from '../../services/services';
import { IsAdministratorUserLogged } from '../Utils/UtilFunctions';

const Home = () => {
  const [gardenInfo, setGardenInfo] = useState([]);
  const [filters, setFilters] = useState({ show: false, message: '' });
  const [registerResponse, setRegisterResponse] = useState({ error: false, message: '' });

  const FilterSearch = filters => {
    var body = {
      consulta: filters.search,
      ubicacion: filters.district !== '' ? filters.district : null,
      categoria: filters.categoryType !== '' ? filters.categoryType : null,
    };
    setFilters({ search: filters.search, district: filters.district, categoryType: filters.categoryType });
    apiPost('huerta/search/', body).then(r => {
      if (r.error == false) {
        var gardenMap = [];
        r.res.map(item => {
          var articles = '';
          if (item.articulo) {
            item.articulo.forEach(article => {
              if (articles == '') {
                articles += article.nombre;
              } else {
                articles += ', ' + article.nombre;
              }
            });
            if (articles == '') {
              articles = 'No contiene artículos';
            }
          }
          if ((!IsAdministratorUserLogged() && item.estado !== 'ARCHIVADA') || IsAdministratorUserLogged()) {
            gardenMap.push({
              id: item.id,
              name: item.nombre,
              nets: item.redes_names,
              tel: item.referente?.telefono,
              location: item.ubicacion?.direccion,
              email: item.referente?.email,
              updateDate: item.updated_at,
              lat: item.ubicacion?.latitud,
              long: item.ubicacion?.longitud,
              contains: articles,
              publicContact: item?.contacto_es_publico,
              isPublic: item?.es_publica,
              status: item?.estado,
            });
          }
        });
        setGardenInfo(gardenMap);
      } else {
        setRegisterResponse({ show: true, error: r.error, message: r.res });
      }
    });
  };

  return (
    <div className={style.body}>
      <div className={style.background}>
        <SearchBar GardenFilter={FilterSearch} />
        <div className={style.list}>
          <List info={gardenInfo} filters={filters} />
        </div>
      </div>
    </div>
  );
};

export default Home;

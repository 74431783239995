import React from 'react';
import { Row, Col, CardTitle, Card, CardBody } from 'reactstrap';
import style from './style.module.scss';
import { useHistory } from 'react-router-dom';

const UserGardenItem = ({ item }) => {
  const history = useHistory();
  return (
    <Card body className={style.cardGardenItem} onClick={() => history.push('/garden/' + item.id)}>
      <CardTitle className={style.cardTitle}>
        <Row>
          <Col className={style.gardenName}>{item.name}</Col>
          <Col className={style.status}>{item.status}</Col>
        </Row>
      </CardTitle>
      <CardBody>
        <Row>
          <Col className={style.left}>
            <div className={style.alignLeft}>
              <i className="fas fa-map-marker-alt" /> {'  '}
              {item.location}
            </div>
          </Col>
        </Row>
        {item.nets?.length > 0 && (
          <Row>
            <Col className={style.left}>Red: {item.nets.toString().replaceAll(',', ', ')}</Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default UserGardenItem;

import React, { useEffect, useState } from 'react';
import {
  CardBody,
  Card,
  Row,
  Col,
  Button,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Tooltip,
  TabPane,
  TabContent,
} from 'reactstrap';
import AdminArticles from '../../Article/AdminArticles';
import AdminEvents from '../../Events/AdminEvents/AdminEvents';
import AdminUserList from '../../Users/AdminUserList';
import AdminNetList from '../../Net/AdminNetList';
import style from './style.module.scss';
import { Redirect } from 'react-router';
import AdminGardenList from '../../Garden/AdminGardenList';
import AdminRequest from '../../Request/AdminRequest';

const AdminHome = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (!(localStorage.getItem('is_superuser') === 'true' || localStorage.getItem('is_staff') === 'true')) {
    return <Redirect to="/home" />;
  }

  return (
    <div>
      <Nav tabs className={style.nav}>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? style.navLinkActive : style.navLink}
            onClick={() => {
              toggle('1');
            }}
          >
            Solicitudes
          </NavLink>
        </NavItem>
        {localStorage.getItem('is_superuser') === 'true' && (
          <NavItem>
            <NavLink
              className={activeTab === '2' ? style.navLinkActive : style.navLink}
              active={activeTab === '2'}
              onClick={() => {
                toggle('2');
              }}
            >
              Usuarios
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            className={activeTab === '3' ? style.navLinkActive : style.navLink}
            active={activeTab === '3'}
            onClick={() => {
              toggle('3');
            }}
          >
            Huertas
          </NavLink>
        </NavItem>
        {localStorage.getItem('is_superuser') === 'true' && (
          <NavItem>
            <NavLink
              className={activeTab === '4' ? style.navLinkActive : style.navLink}
              active={activeTab === '4'}
              onClick={() => {
                toggle('4');
              }}
            >
              Eventos
            </NavLink>
          </NavItem>
        )}
        {localStorage.getItem('is_superuser') === 'true' && (
          <NavItem>
            <NavLink
              className={activeTab === '5' ? style.navLinkActive : style.navLink}
              active={activeTab === '5'}
              onClick={() => {
                toggle('5');
              }}
            >
              Artículos
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            className={activeTab === '6' ? style.navLinkActive : style.navLink}
            active={activeTab === '6'}
            onClick={() => {
              toggle('6');
            }}
          >
            Redes
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <AdminRequest />
        </TabPane>
        {localStorage.getItem('is_superuser') === 'true' && (
          <TabPane tabId="2">
            <AdminUserList />
          </TabPane>
        )}
        <TabPane tabId="3">
          <AdminGardenList />
        </TabPane>
        {localStorage.getItem('is_superuser') === 'true' && (
          <TabPane tabId="4">
            <AdminEvents />
          </TabPane>
        )}
        {localStorage.getItem('is_superuser') === 'true' && (
          <TabPane tabId="5">
            <AdminArticles />
          </TabPane>
        )}
        <TabPane tabId="6">
          <AdminNetList />
        </TabPane>
      </TabContent>
    </div>
  );
};
export default AdminHome;

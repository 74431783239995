import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  IsUserLogged,
  IsSameUserLogged,
  IsAdministratorUserLogged,
  IsStaffUserLogged,
} from '../../Utils/UtilFunctions';
import style from './style.module.scss';

const UserEventItem = ({ item, updateEvent, deleteEvent }) => {
  const [hasEditingPermission, setHasEditingPermission] = useState(false);

  useEffect(() => {
    const userPermission =
      IsUserLogged() && (IsSameUserLogged(item.creator) || IsAdministratorUserLogged() || IsStaffUserLogged());
    setHasEditingPermission(userPermission);
  }, []);

  return (
    <div className={style.card}>
      <div className={style.borderBottom}>
        <Col className={style.eventTitle}>
          <div className={style.itemIcon}>
            <div className={style.icon}>
              <i className="far fa-calendar-alt" />
            </div>
            {item.name}
          </div>
        </Col>
        <Col className={style.updateDate}>
          {item.date.toLocaleString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
          hs
          {hasEditingPermission && (
            <>
              <Col className={style.iconEdit} onClick={() => updateEvent(item.id)}>
                <i className="fas fa-pen" />
              </Col>
              <Col className={style.iconDelete} onClick={() => deleteEvent(item.id)}>
                <i className="fas fa-trash" />
              </Col>
            </>
          )}
        </Col>
      </div>
      {(item.description !== '' || item.address !== '' || item.isContest) && (
        <div className={style.infoEvent}>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12} className={style.itemAddress}>
              <div className={style.itemIcon}>
                <div className={style.icon}>
                  <i className="fas fa-map-marker-alt" />
                </div>
                {item.address}
              </div>
              <div>{item.isContest && <span className={style.isContest}>concurso</span>}</div>
            </Col>
          </Row>
          {item.description !== '' && (
            <Row>
              <Col lg={6} md={6} sm={12} xs={12} className={style.itemDescription}>
                {item.description}
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default UserEventItem;

import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CardBody, Card, Row, Col, FormGroup, Form, Label, Input, Button, NavLink, Container } from 'reactstrap';
import { apiPost } from '../../services/services';
import Error from '../Utils/Error';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import style from './style.module.scss';

const Login = () => {
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [redirectRegister, setRedirectRegister] = useState(false);
  const [redirectHome, setRedirectHome] = useState(false);
  const [redirectForgotPassword, setRedirectForgotPassword] = useState(false);
  const [authFails, setAuthFails] = useState({ error: false, message: '' });

  const submitForm = e => {
    e.preventDefault();
    const body = {
      username: loginForm.email,
      password: loginForm.password,
    };
    apiPost('usuario/ingresar/', body).then(r => {
      if (r.error == false) {
        localStorage.setItem('token', r.res.token);
        localStorage.setItem('currentUser', JSON.stringify(loginForm.email));
        localStorage.setItem('is_staff', r.res.is_staff);
        localStorage.setItem('is_superuser', r.res.is_superuser);
        setRedirectHome(true);
      } else {
        let message = '';
        Object.entries(r.res).forEach(([key, errors], index) => {
          message = `${message}${index > 0 ? ',' : ''} ${errors.toString()}`;
        });
        setAuthFails({ error: r.error, message });
      }
    });
  };

  const onChange = (name, value) => {
    setLoginForm({ ...loginForm, [name]: value });
  };

  if (redirectHome) {
    return <Redirect to="/home" />;
  }
  if (redirectRegister) {
    return <Redirect to="/register" />;
  }
  if (redirectForgotPassword) {
    return <Redirect to="/password-reset" />;
  }

  return (
    <div className={style.body}>
      <div className={style.center}>
        <Card className={style.card}>
          <CardBody>
            {authFails.error && (
              <Row>
                <Col>
                  <Error message={authFails.message} />
                </Col>
              </Row>
            )}
            <Row>
              <Col className={style.title}>Ingrese a su usuario</Col>
            </Row>
            <Row className={style.center}>
              <Col md="10">
                <Form className={style.Form} onSubmit={e => submitForm(e)}>
                  <FormGroup className={style.FormGroup}>
                    <div className={style.labelContainer}>
                      <Label for="email">Correo electrónico</Label>
                      <Label className={style.required}>(obligatorio)</Label>
                    </div>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Correo electrónico"
                      value={loginForm.email}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className={style.FormGroup}>
                    <div className={style.labelContainer}>
                      <Label for="password">Contraseña</Label>
                      <Label className={style.required}>(obligatorio)</Label>
                    </div>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Contraseña"
                      value={loginForm.password}
                      onChange={e => onChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <NavLink className={style.forgotPassword} onClick={() => setRedirectForgotPassword(true)}>
                    ¿Olvidaste tu contraseña?
                  </NavLink>
                  <Row className={style.Button}>
                    <Col>
                      <Button type="submit" className={style.ButtonPrimary} size="lg">
                        Confirmar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Container>
              <Row className={style.Bottom}>
                <Col>¿No tenés un usuario?</Col>
              </Row>
              <Row>
                <Col>
                  <Button className={style.createUserButton} onClick={() => setRedirectRegister(true)}>
                    Crear un usuario
                  </Button>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Login;

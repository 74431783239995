import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, CardBody, Card, Container, Button } from 'reactstrap';
import style from './style.module.scss';
import { apiGet } from '../../services/services';
import AdminGardenListItem from './AdminGardenListItem';
import { Redirect } from 'react-router';
import UpdateGardenReferent from './UpdateGardenReferent';
import { GetLabelGardenType } from '../Utils/UtilFunctions';

const AdminGardenList = () => {
  const [gardenList, setGardenList] = useState({ activa: [], inactiva: [], archivada: [] });
  const [registerGarden, setRegisterGarden] = useState(false);
  const [usersModal, setUsersModal] = useState(false);
  const [selectedGarden, setSelectedGarden] = useState(0);

  useEffect(() => {
    apiGet('huerta/', {}).then(r => {
      if (r.error == false) {
        const activa = [];
        const inactiva = [];
        const archivada = [];
        r.res.forEach(item => {
          if (item.fue_aceptada) {
            switch (item.estado) {
              case 'ACTIVA':
                activa.push({
                  id: item.id,
                  name: item.nombre,
                  email: item.referente?.email,
                  tel: item.referente?.telefono,
                  location: item.ubicacion?.direccion,
                  type: GetLabelGardenType(item.tipo),
                });
                break;
              case 'INACTIVA':
                inactiva.push({
                  id: item.id,
                  name: item.nombre,
                  email: item.referente?.email,
                  tel: item.referente?.telefono,
                  location: item.ubicacion?.direccion,
                  type: GetLabelGardenType(item.tipo),
                });
                break;
              case 'ARCHIVADA':
                archivada.push({
                  id: item.id,
                  name: item.nombre,
                  email: item.referente?.email,
                  tel: item.referente?.telefono,
                  location: item.ubicacion?.direccion,
                  type: GetLabelGardenType(item.tipo),
                });
                break;
              default:
                break;
            }
          }
        });
        setGardenList({ activa, inactiva, archivada });
      }
    });
  }, []);

  const openUsersModal = useCallback(gardenId => {
    console.log(gardenId);
    setUsersModal(true);
    setSelectedGarden(gardenId);
  });

  if (registerGarden) {
    return <Redirect to="/registergarden" />;
  }

  return (
    <div className={style.body}>
      <div className={style.background}>
        {usersModal && <UpdateGardenReferent gardenId={selectedGarden} toggle={setUsersModal} />}
        <Container className={style.containerAdmin} fluid>
          <Row className={style.right}>
            <Col className={style.titleAdmin}>Huertas</Col>
            <Col sm={{ size: 'auto' }}>
              <Button size="sm" className={style.buttonAdd} onClick={() => setRegisterGarden(true)}>
                {' '}
                <i className="fas fa-plus" />
                {'  '}Nueva Huerta
              </Button>
            </Col>
          </Row>
        </Container>
        <div className={style.titleDate}>{'Activas'}</div>
        {gardenList.activa.length > 0 ? (
          <Card>
            <CardBody>
              {gardenList.activa.map((item, i) => {
                return (
                  <div key={i}>
                    <AdminGardenListItem item={item} id={i} openUsersModal={openUsersModal} />
                  </div>
                );
              })}
            </CardBody>
          </Card>
        ) : (
          <div>No hay huertas para mostrar</div>
        )}
        <div className={style.titleDate}>{'Inactivas'}</div>
        {gardenList.inactiva.length > 0 ? (
          <Card>
            <CardBody>
              {gardenList.inactiva.map((item, i) => {
                return (
                  <div key={i}>
                    <AdminGardenListItem item={item} id={i} openUsersModal={openUsersModal} />
                  </div>
                );
              })}
            </CardBody>
          </Card>
        ) : (
          <div>No hay huertas para mostrar</div>
        )}
        <div className={style.titleDate}>{'Archivadas'}</div>
        {gardenList.archivada.length > 0 ? (
          <Card>
            <CardBody>
              {gardenList.archivada.map((item, i) => {
                return (
                  <div key={i}>
                    <AdminGardenListItem item={item} id={i} openUsersModal={openUsersModal} />
                  </div>
                );
              })}
            </CardBody>
          </Card>
        ) : (
          <div>No hay huertas para mostrar</div>
        )}
      </div>
    </div>
  );
};

export default AdminGardenList;

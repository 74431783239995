import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import style from './style.module.scss';
import { useHistory } from 'react-router-dom';

const AdminRequestGardenListItem = ({ item, id, confirmRequest }) => {
  const history = useHistory();
  return (
    <div className={id > 0 ? style.borderTop : undefined}>
      <Container className={style.gardenItem} fluid>
        <Row xs="1" sm="1" md="3" lg="4">
          <Col className={style.alignLeft} onClick={() => history.push('/garden/' + item.id)}>
            <div className={style.infoContainer}>
              <div className={style.selectItem}>{item.name}</div>
            </div>
          </Col>
          <Col className={style.alignLeft} onClick={() => history.push('/garden/' + item.id)}>
            <div className={style.infoContainer}>
              <div className={style.iconContainer}>
                <i className="fas fa-envelope"></i>
              </div>
              &nbsp;
              <div className={style.selectItem}>{item.email}</div>
            </div>
          </Col>
          <Col className={style.alignLeft} onClick={() => history.push('/garden/' + item.id)}>
            {item.tel && (
              <div className={style.infoContainer}>
                <div className={style.iconContainer}>
                  <i className="fas fa-phone"></i>
                </div>
                &nbsp;
                {item.tel}
              </div>
            )}
          </Col>
          <Col className={style.alignLeft} md="12">
            <div className={style.buttonDisplay}>
              <div className={style.buttonWidth}>
                <Button
                  type="submit"
                  className={style.buttonAdd}
                  sm={{ size: 'auto' }}
                  onClick={() => confirmRequest(item.id, item.name, true)}
                >
                  Aceptar
                </Button>
              </div>
              &nbsp;
              <div className={style.buttonWidth}>
                <Button
                  className={style.buttonReject}
                  sm={{ size: 'auto' }}
                  onClick={() => confirmRequest(item.id, item.name, false)}
                >
                  Rechazar
                </Button>

                {'  '}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminRequestGardenListItem;

import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { CardBody, Card, Input, Button, CardTitle, CardSubtitle, ButtonGroup } from 'reactstrap';
import { apiPost } from '../../services/services';
import Error from '../Utils/Error';
import Success from '../Utils/Success';
import { fieldsTranslations } from '../Utils/UserFieldsTranslations';
import style from './style.module.scss';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [registerResponse, setRegisterResponse] = useState({ show: false, error: false, message: '' });
  const [redirect, setRedirect] = useState(false);

  const history = useHistory();

  const submit = () => {
    apiPost('usuario/password-reset/', { email }).then(r => {
      if (r.error == false) {
        setRegisterResponse({
          show: true,
          error: r.error,
          message:
            'Email enviado correctamente. Para continuar con el reseteo, verifique su casilla y siga los pasos que indica el email.',
        });
        setTimeout(() => history.push('/home'), 5000);
      } else {
        let message = 'Error en:';
        Object.entries(r.res).forEach(([key, errors], index) => {
          message = `${message}${index > 0 ? ',' : ''} ${fieldsTranslations[key] ?? key} (${errors
            .toString()
            .toLowerCase()})`;
        });
        setRegisterResponse({ show: true, error: r.error, message });
      }
    });
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={style.center}>
      <Card className={style.card}>
        <CardBody>
          {registerResponse.show && (
            <div>
              {registerResponse.error ? (
                <Error message={registerResponse.message} />
              ) : (
                <Success message={registerResponse.message} />
              )}
            </div>
          )}
          <CardTitle className={style.title}>Recuperar contraseña</CardTitle>
          <CardSubtitle tag="h6" className={style.subtitle}>
            Para recuperar su contraseña ingrese su correo electrónico y le enviaremos las intrucciones
          </CardSubtitle>
          <Input
            className={style.inputSearch}
            type="email"
            name="email"
            id="email"
            placeholder="Email del usuario"
            onChange={e => setEmail(e.target.value)}
          />
          <div className={style.buttonGroup}>
            <Button className={style.ButtonSecondary} onClick={() => setRedirect(true)}>
              Cancelar
            </Button>
            <Button className={style.ButtonPrimary} onClick={() => submit()}>
              Confirmar
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PasswordReset;

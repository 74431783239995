import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, Spinner } from 'reactstrap';
import style from './style.module.scss';
import { Link } from 'react-router-dom';
import { apiDelete, apiGet } from '../../../services/services';
import UserEventItem from './UserEventItem';
import { isSameDay, IsUserLogged } from '../../Utils/UtilFunctions';
import { MonthNames } from '../../Utils/UtilsData';
import RegisterEvent from '../RegisterEvent';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import Error from '../../Utils/Error';
import Success from '../../Utils/Success';

const UserEvents = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [createPermission, setCreatePermission] = useState(false);
  const [editEvent, setEditEvent] = useState({ show: false, item: {} });
  const [confirmationModalDelete, setConfirmationModalDelete] = useState({
    show: false,
    reference: '',
    eventId: '',
  });
  const [deleteResponse, setDeleteResponse] = useState({
    show: false,
    error: false,
    message: '',
  });

  useEffect(() => {
    apiGet('evento/', {}).then(r => {
      setLoading(false);
      if (r.error == false) {
        setAllEvents(r.res);
        var eventArray = r.res.map(item => {
          return {
            id: item.id,
            name: item.nombre,
            description: item.descripcion,
            address: item.ubicacion?.direccion,
            date: new Date(item.fecha_hora),
            creator: item.creador_email,
            isContest: item.es_concurso,
          };
        });
        var eventsMap = [];
        const sortEvents = eventArray.sort((a, b) => b.date - a.date);
        var dateExists = false;
        sortEvents.map(e => {
          dateExists = false;
          eventsMap.forEach(elem => {
            if (isSameDay(elem.date, e.date)) {
              elem.array.push(e);
              dateExists = true;
            }
          });
          if (!dateExists) {
            eventsMap.push({ date: e.date, array: [e] });
          }
        });
        setEvents(eventsMap);
        var hasPermission = IsUserLogged();
        setCreatePermission(hasPermission);
      }
    });
  }, []);

  const UpdateEvent = useCallback(id => {
    const eventItem = allEvents.filter(event => event.id == id)?.[0];
    setEditEvent({ ...editEvent, show: true, item: eventItem });
  });

  const deleteEvent = useCallback(id => {
    setConfirmationModalDelete({ show: true, reference: id, eventId: id });
  });

  const confirmDeleteEvent = id => {
    apiDelete('evento/' + id + '/').then(r => {
      if (r.error) {
        setDeleteResponse({
          show: true,
          error: false,
          message: 'No se pudo eliminar el evento',
        });
      } else {
        setDeleteResponse({
          show: true,
          error: false,
          message: 'Evento eliminado correctamente',
        });
        setTimeout(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        }, 1000);
      }
    });
  };

  return (
    <div className={style.body}>
      <div className={style.background}>
        {deleteResponse.show && (
          <Row>
            <Col>
              {deleteResponse.error ? (
                <Error message={deleteResponse.message} />
              ) : (
                <Success message={deleteResponse.message} />
              )}
            </Col>
          </Row>
        )}
        <ConfirmationModal
          isOpen={confirmationModalDelete.show}
          title="Eliminar evento"
          message="eliminar el evento"
          onConfirm={() => confirmDeleteEvent(confirmationModalDelete.eventId)}
          onClose={() => setConfirmationModalDelete({ show: false, reference: '' })}
        />
        {editEvent.show && (
          <div className={style.overlay}>
            <div className={style.body}>
              <div className={style.center}>
                <RegisterEvent update={true} item={editEvent.item} closePopUp={setEditEvent} />
              </div>
            </div>
          </div>
        )}
        <Row className={style.title}>
          <Col className={style.left}>Eventos</Col>
          <Col className={style.right}>
            {createPermission && (
              <Link className={style.linkBtnPrimary} to="/registerevent">
                {' '}
                <Button className={style.ButtonPrimary}>
                  <i className="fas fa-plus" />
                  {'  '}Agregar Evento
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Row className={`${style.bodyEvent} ${loading ? style.loading : ''}`}>
          {loading ? (
            <Spinner />
          ) : events.length > 0 ? (
            <Col className={style.eventGroup}>
              {events.map((item, i) => {
                return (
                  <div key={i}>
                    <Row>
                      <Col className={style.titleDate}>
                        {item.date.getDate()} de {MonthNames[item.date.getMonth()]}, {item.date.getFullYear()}
                      </Col>
                    </Row>
                    <Row className={style.eventInfoItem} key={i}>
                      {item.array.map((eventItem, i) => {
                        return (
                          <Col md={6} sm={12} xs={12} lg={6} key={i}>
                            {' '}
                            <UserEventItem item={eventItem} updateEvent={UpdateEvent} deleteEvent={deleteEvent} />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
            </Col>
          ) : (
            <>No se han encontrado eventos futuros.</>
          )}
        </Row>
      </div>
    </div>
  );
};

export default UserEvents;
